import { Link, useNavigate } from "react-router-dom";
import { UIDataTypes } from "../../common/DataTypes";
import { removeLogin } from "../../common/Utilities";
import SVGIconInbox from "../svg/SVGIconInbox";
import SVGIconLogout from "../svg/SVGIconLogout";
import SVGIconUser from "../svg/SVGIconUser";
import { PageLinks } from "../../common/Constants";

const HeaderProfile: React.FC<{ coachProfile: UIDataTypes.CoachProfile }> = ({ coachProfile }) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        removeLogin();
        navigate(PageLinks.LOGIN);
    }

    return (
        <li className="nav-item dropdown header-profile">
            <a className="nav-link" href="#" onClick={e => e.preventDefault()} role="button" data-toggle="dropdown"><img src={(coachProfile.coachProfilePicture !== '') ? coachProfile.coachProfilePicture : window.location.origin + "/assets/images/logo.png"} alt="" /></a>
            <div className="dropdown-menu dropdown-menu-right">
                <Link to={PageLinks.MY_PROFILE_VIEW} className="dropdown-item ai-icon">
                    <SVGIconUser />
                    <span className="ml-2">Profile </span>
                </Link>
                {/* <a href="javascript:void(0);" onClick={(e) => { e.preventDefault(); }} className="dropdown-item ai-icon">
                    <SVGIconInbox />
                    <span className="ml-2">Inbox </span>
                </a> */}
                <a href="javascript:void(0);" onClick={(e) => { e.preventDefault(); handleLogout(); }} className="dropdown-item ai-icon">
                    <SVGIconLogout />
                    <span className="ml-2">Logout </span>
                </a>
            </div>
        </li>
    )
}

export default HeaderProfile;