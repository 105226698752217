import { Link, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import { useAppStateAPI } from "../common/AppStateAPI";
import { useEffect, useRef, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import { persistLoginDetail } from "../common/Utilities";

const Login: React.FC = () => {
    const navigate = useNavigate();

    const inputMobileNumRef = useRef<HTMLInputElement>(null);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [navigateAfterSuccess, setNavigateAfterSuccess] = useState(0);

    const requestLogin = () => {
        if (!mobile) {
            toast.error('Please enter your Mobile')
        } else if (!password) {
            toast.error('Please enter your Password')
        } else {
            addProcessingRequests();
            ServerAPI.coachLogin(mobile, password).then(response => {
                if (response.success) {
                    toast.success(response.message);
                    if (response.coach) {
                        const coach = response.coach;
                        persistLoginDetail(coach.coach_id, coach.coach_api_key, coach.coach_full_name, coach.coach_profile_picture);
                        setNavigateAfterSuccess(coach.coach_preferred_name ? 1 : 2)
                        // if (coach.coach_preferred_name) {
                        //     console.log('Navigating to Dashboard');
                        //     navigate(PageLinks.DASHBOARD);
                        // } else {
                        //     console.log('Navigating to Profile');
                        //     navigate(PageLinks.MY_PROFILE_UPDATE);
                        // }
                    }
                    // setTimeout(() => {
                    //     setNavigateToDashboard(true);
                    // }, 500);
                } else {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Prevent form submission
            event.preventDefault();

            // Call your API here
            requestLogin();
        }
    }

    useEffect(() => {
        if (navigateAfterSuccess === 1) {
            console.log('Navigating to Dashboard');
            setTimeout(() => navigate(PageLinks.DASHBOARD), 500);
        } else if (navigateAfterSuccess === 2) {
            console.log('Navigating to Profile');
            setTimeout(() => navigate(PageLinks.MY_PROFILE_UPDATE), 500);
        }
    }, [navigateAfterSuccess])

    useEffect(() => {
        if (inputMobileNumRef.current) {
            inputMobileNumRef.current.focus();
        }
    }, []);

    return (
        <div className="authincation h-100vh login_bg">
            <div className="container h-100vh">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-12">
                        <div className="authincation-content white_bg">
                            <div className="row no-gutters">
                                <div className="col-xl-7">
                                    <div className="auth-form">
                                        <h4 className="text-left mb-4 text-white login_left_head">Welcome Back to Sathwell Coach Central</h4>
                                        <div className="login_left_img"><img src={window.location.origin + "/assets/images/login_left_img.svg"} alt="Welcome Back to Sathwell Coach Central" /></div>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="auth-form login_right">
                                        <h3 className="text-left mb-4 text-dark-gray">Sign In</h3>
                                        <form action="index.html" className="login_form">
                                            <div className="form-group">
                                                <label className="mb-1 text-dark-gray"><strong>Mobile Number</strong></label>
                                                <input type="text" className="form-control" placeholder="Enter Mobile Number" value={mobile} onChange={(evt) => setMobile(evt.target.value)} ref={inputMobileNumRef} />
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 text-dark-gray"><strong>Password</strong></label>
                                                <input type="password" className="form-control" placeholder="Enter Password" value={password} onChange={(evt) => setPassword(evt.target.value)} onKeyDown={handleEnterKey} />
                                            </div>
                                            <div className="text-center">
                                                <button type="button" className="btn bg-pink primary_btn text-primary btn-block" onClick={requestLogin}>{" "}Sign In</button>
                                            </div>
                                        </form>
                                        <div className="new-account mt-3">
                                            <p className="text-dark-gray text-center">
                                                <Link to={PageLinks.RESET_PASSWORD} className="text-dark-gray">Forgot Password?</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
