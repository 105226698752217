import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { PageLinks } from "./common/Constants";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import PasswordConfirm from "./pages/PasswordConfirm";
import AppToast from "./components/ui_components/AppToast";
import Preloader from "./components/ui_components/Preloader";
import PageTemplate from "./pages/PageTemplate";
import { isCustomerLoggedIn } from "./common/Utilities";

const AppNavigate: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={PageLinks.LOGIN} element={<Login />} />
                <Route path={PageLinks.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={PageLinks.PASSWORD_CONFIRM} element={<PasswordConfirm />} />
                {/* <Route path={PageLinks.PAGE + "/*"} element={isCustomerLoggedIn() ? <PageTemplate /> : <Navigate to={PageLinks.LOGIN} />} /> */}
                <Route path={PageLinks.PAGE + "/*"} element={<PageTemplate />} />
                <Route path="/" element={<Navigate to={PageLinks.LOGIN} />} />
            </Routes>
            <AppToast />
            <Preloader />
        </Router>
    );
};

export default AppNavigate;
