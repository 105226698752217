export const PageURLNames = {
    DASHBOARD: "/dashboard",
    LISTING_ENTRY: "/listing-entry",
    ADD_LISTING: "/add-listing",
    MY_PROFILE_UPDATE: "/my-profile-update",
    MY_PROFILE_VIEW: "/my-profile-view",
    MY_LISTING: "/my-listing",
    DRAFT_LISTINGS: "/draft-listings",
    SUBMITTED_LISTINGS: "/submitted-listings",
    PUBLISHED_LISTINGS: "/published-listings",
    DRAFT_LISTING_DETAIL: "/draft-listing-detail",
    LISTING_DETAIL: "/listing-detail",
    PENDING_COACH_FEEDBACK: "/pending-coach-feedback",
    BYTE_SIZED_COACH_RESPONSES: "/byte-sized-coach-responses",
    PARTICIPANT_DETAILS: "/participant-details",
    POPUPADDMETRICS: "/popupaddmetrics",
    POPUPEDITMETRICS: "/popupeditmetrics",
    POPUPADDPERFORMANCEMETRICS: "/popupaddperformancemetrics",
    POPUPEDITPERFORMANCEMETRICS: "/popupeditperformancemetrics",
    POPUPPARTICIPANTSFEEDBACK: "/popupparticipantsfeedback",
    PROFILEUPDATESUCCESS: "/profileupdatesuccess",
    PARTICIPANTPERFORMANCELIST: "/particpantperformancelist",
    PARTICIPANTPERFORMANCEMETRIC: "/particpantperformancemetric",
    PARTICIPANTDETAILSTWO: "/participantdetailstwo",
    VERTICALDETAILS: "/verticaldetails",
    VERTICALDETAILSONE: "/verticaldetailsone",
    VERTICALDETAILSTWO: "/verticaldetailstwo",
    NOOFPARTICIPANTS: "/noofparticipants",
    ARTICLEDETAILS: "/articledetails",
    ADDBYTESIZECHALLENGE: "/addbytesizechallenge",
    ADDPROGRAM: "addprogram",
    ADDWORKSHOP: "addworkshop",
    ADDARTICLE: "addarticle",
    TABLISTING: "/tablisting",
    PARTICIPANTS: "/participants",
    PROGRAMLISITING: "/programlisting",
    SESSIONLISTING: "/sessionlisting",
    EARNINGS: "/earnings",
}

export const PageLinks = {
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password",
    PASSWORD_CONFIRM: "/password-confirm",
    PAGE: "/page",
    DASHBOARD: "/page" + PageURLNames.DASHBOARD,
    LISTING_ENTRY: "/page" + PageURLNames.LISTING_ENTRY,
    ADD_LISTING: "/page" + PageURLNames.ADD_LISTING,
    MY_PROFILE_UPDATE: "/page" + PageURLNames.MY_PROFILE_UPDATE,
    MY_PROFILE_VIEW: "/page" + PageURLNames.MY_PROFILE_VIEW,
    MY_LISTING: "/page" + PageURLNames.MY_LISTING,
    DRAFT_LISTINGS: "/page" + PageURLNames.DRAFT_LISTINGS,
    SUBMITTED_LISTINGS: "/page" + PageURLNames.SUBMITTED_LISTINGS,
    PUBLISHED_LISTINGS: "/page" + PageURLNames.PUBLISHED_LISTINGS,
    DRAFT_LISTING_DETAIL: "/page" + PageURLNames.DRAFT_LISTING_DETAIL,
    LISTING_DETAIL: "/page" + PageURLNames.LISTING_DETAIL,
    PENDING_COACH_FEEDBACK: "/page" + PageURLNames.PENDING_COACH_FEEDBACK,
    BYTE_SIZED_COACH_RESPONSES: "/page" + PageURLNames.BYTE_SIZED_COACH_RESPONSES,
    PARTICIPANT_DETAILS: "/page" + PageURLNames.PARTICIPANT_DETAILS,
    EARNINGS: "/page" + PageURLNames.EARNINGS,

    POPUPADDMETRICS: "/popupaddmetrics",
    POPUPEDITMETRICS: "/popupeditmetrics",
    POPUPADDPERFORMANCEMETRICS: "/popupaddperformancemetrics",
    POPUPEDITPERFORMANCEMETRICS: "/popupeditperformancemetrics",
    POPUPPARTICIPANTSFEEDBACK: "/popupparticipantsfeedback",
    PROFILEUPDATESUCCESS: "/profileupdatesuccess",
    PARTICIPANTPERFORMANCELIST: "/particpantperformancelist",
    PARTICIPANTPERFORMANCEMETRIC: "/particpantperformancemetric",
    PARTICIPANTDETAILSTWO: "/participantdetailstwo",
    VERTICALDETAILS: "/verticaldetails",
    VERTICALDETAILSONE: "/verticaldetailsone",
    VERTICALDETAILSTWO: "/verticaldetailstwo",
    NOOFPARTICIPANTS: "/noofparticipants",
    ARTICLEDETAILS: "/articledetails",
    ADDBYTESIZECHALLENGE: "/addbytesizechallenge",
    ADDPROGRAM: "addprogram",
    ADDWORKSHOP: "addworkshop",
    ADDARTICLE: "addarticle",

    TABLISTING: "/tablisting",
    PARTICIPANTS: "/participants",
    PROGRAMLISITING: "/programlisting",
    SESSIONLISTING: "/sessionlisting"
}

export const EndPoints = {
    CoachLogin: "coach_login",
    CoachForgotPassword: "coach_forgot_password",
    CoachPasswordReset: "coach_password_reset",
    Languages: "languages",
    Countries: "countries",
    States: "states",
    SocialMediaTypes: "social_media_types",
    Verticals: "verticals",
    SubVerticals: "sub_verticals",
    Categories: "categories",
    ByteSizeDays: "byte_size_days",
    CoachDashboard: "coach_dashboard",
    Coaches: "coaches",
    DraftListings: "draft_listings",
    Listings: "listings",
    ListingPurchases: "listing_purchases",
    ListingProgramMetrics: "listing_program_metrics",
    ListingProgramMetricRequests: "listing_program_metric_requests",
    CustomerByteChallenges: "customer_byte_challenges",
    ListingPurchaseCoachPayouts: "listing_purchase_coach_payouts",
}



export const API = {
    //BaseUrl: "http://www.sathwelllocal.com/api/",
    BaseUrl: "https://sathwell.sitepreview.in/api/",
    EndPoint: EndPoints
}

export const ProjectConfig = {
    EntriesPerPage: 10,
    ServerTimezone: 'Asia/Singapore',
}

export const ListCategory = {
    ByteSizeChallenge: 1,
    Program: 2,
    LiveSession: 3,
    Workshop: 4,
    NewsArticle: 5,
};

export const Gender = {
    Male: 1,
    Female: 2,
    Transgender: 3,
    NonBinary: 4
}

export const MaritalStatus = {
    Single: 1,
    Married: 2,
    Separated: 3,
    Divorced: 4,
    Widowed: 5
}

export const BankAccountType = {
    Savings: 1,
    Current: 2
}

export const ListingMode = {
    Offline: 1,
    Online: 2
}

export const ListingDaySession = {
    Anytime: 10,
    Morning: 20,
    Afternoon: 30,
    Evening: 40,
    Night: 50,
    Custom: 60,
}

export const ListingStatus = {
    Draft: 10,
    Submitted: 20,
    UnderReview: 25,
    Rejected: 30,
    Published: 35,
    Open: 50,
    Closed: 55,
    Completed: 60,
    OnHold: 65,
    Terminated: 70
}

export const ListingPurchaseProgressStatus = {
    Open: 5,
    Scheduled: 10,
    Halted: 20,
    InProgress: 30,
    Submitted: 35,
    Completed: 40
}

export const CustomerByteChallengeStatus = {
    Scheduled: 10,
    Halted: 20,
    InProgress: 30,
    Submitted: 35,
    Completed: 40
}

export const MetricInputType = {
    Text: 1,
    Number: 2
}

export const EarningPayoutStatus = {
    Pending: 0,
    Completed: 1
}

export const DateFormats = {
    DDMMYYYY: 'DD/MM/YYYY',
    DDMONYYYY: 'DD MMM YYYY',
    DDMONTHYYYY: 'DD MMMM YYYY',
    YYYYMMDD: 'YYYY-MM-DD'
}

export const TimeFormats = {
    H12MIN_AMPM: 'hh:mm a',
    H24MIN: 'HH:mm'
}

export const ProjectStrings = {
    ValidationRequired: 'Required',
    ValidationSelect: 'Please Select',
    ValidationEmail: 'Not a Valid Email',
    ValidationNumber: 'Not a Valid Numeric',
    ValidationMax: (max: number) => 'Max ' + max + ' Characters',
    ValidationMin: (min: number) => 'Min ' + min + ' Characters',
    ErrorAPIFailed: 'Error Occurred while processing the request'
}