import { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { APIData } from '../../common/DataTypes';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { toast } from 'react-hot-toast';
import { getDataFromAPI } from '../../common/Utilities';
import { EndPoints } from '../../common/Constants';

const PopupListingCategorySelect: React.FC<{ isOpen: boolean; closeModel: any; setValues: any; }> = ({ isOpen, closeModel, setValues }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [verticals, setVerticals] = useState<APIData.Vertical[]>([]);
    const [subVerticals, setSubVerticals] = useState<APIData.SubVertical[]>([]);
    const [categories, setCategories] = useState<APIData.Category[]>([]);
    const [selectedVerticalID, setSelectedVerticalID] = useState(0);
    const [selectedSubVerticalID, setSelectedSubVerticalID] = useState(0);
    const [selectedCategoryID, setSelectedCategoryID] = useState(0);

    useEffect(() => {
        getVerticals();
        getCategories();
    }, []);
    
    useEffect(() => {
        if (selectedVerticalID) {
            getSubVerticals();
        }
    }, [selectedVerticalID]);

    const getVerticals = () => {
        getDataFromAPI(EndPoints.Verticals, setVerticals, addProcessingRequests, reduceProcessingRequests);
    }

    const getSubVerticals = () => {
        getDataFromAPI(EndPoints.SubVerticals, setSubVerticals, addProcessingRequests, reduceProcessingRequests, { 'vertical_id': selectedVerticalID });
    }

    const getCategories = () => {
        getDataFromAPI(EndPoints.Categories, setCategories, addProcessingRequests, reduceProcessingRequests);
    }

    const updateValues = () => {
        if (!selectedVerticalID) {
            toast.error('Please select a Vertical');
        } else if (!selectedSubVerticalID) {
            toast.error('Please select a Sub Vertical');
        } else if (!selectedVerticalID) {
            toast.error('Please select a Category');
        } else {
            setValues(selectedVerticalID, selectedSubVerticalID, selectedCategoryID);
        }
    }

    return (
        <Modal open={isOpen} onClose={closeModel} center>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title text-dark-gray">Add Listing</h4>
                                </div>
                                <div className="card-body card-body-padding">
                                    <div className="mb-3">
                                        <p className="mb-1 popup-subheadings">Category</p>
                                        <select name="category" id="category" className="popup-textbox" value={selectedCategoryID} onChange={(e) => setSelectedCategoryID(parseInt(e.target.value))}>
                                            <option value={0}>Select</option>
                                            {categories.map((category, index) => <option value={category.category_id} key={index}>{category.category_name}</option>)}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <p className="mb-1 popup-subheadings">Vertical</p>
                                        <select name="vertical" id="vertical" className="popup-textbox" value={selectedVerticalID} onChange={(e) => setSelectedVerticalID(parseInt(e.target.value))}>
                                            <option value={0}>Select</option>
                                            {verticals.map((vertical, index) => <option value={vertical.vertical_id} key={index}>{vertical.vertical_name}</option>)}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <p className="mb-1 popup-subheadings">Sub Verticals</p>
                                        <select name="sub_vertical" id="sub_vertical" className="popup-textbox" value={selectedSubVerticalID} onChange={(e) => setSelectedSubVerticalID(parseInt(e.target.value))}>
                                            <option value={0}>Select</option>
                                            {subVerticals.map((subVertical, index) => <option value={subVertical.sub_vertical_id} key={index}>{subVertical.sub_vertical_name}</option>)}
                                        </select>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="submit-btn" onClick={updateValues}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PopupListingCategorySelect;