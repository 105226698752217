import { Link } from "react-router-dom";
import { PageLinks } from "../common/Constants";

const PasswordConfirm: React.FC = () => {
    return (
        <div className="authincation h-100vh login_bg">
            <div className="container h-100vh">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-5">
                        <div className="authincation-content white_bg">
                            <div className="row no-gutters">
                                <div className="col-xl-12 text-center">
                                    <div className="auth-form login_right">
                                        <div className="text-center mb-3">
                                            <img src={window.location.origin + "/assets/images/password_confirm.png"} className="mb-4" alt="Password Confirmation" />
                                        </div>
                                        <h3 className="text-center text-dark-gray">
                                            Password Changed
                                        </h3>
                                        <p className="text-light-blue">
                                            Please Log in with the new Password.
                                        </p>
                                        <div className="text-center">
                                            <Link to={PageLinks.LOGIN} className="btn bg-pink primary_btn text-primary btn-block">Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordConfirm;
