//import moment from "moment";
import moment from 'moment-timezone';
import { UIDataTypes } from "./DataTypes";
import { BankAccountType, DateFormats, Gender, ListCategory, ListingDaySession, ListingMode, ListingStatus, MaritalStatus, MetricInputType, ProjectConfig } from "./Constants";
import { ServerAPI } from "./ServerAPI";
import { toast } from "react-hot-toast";

export const hideCollapseMenu = (collapseMenu: boolean, setCollapseMenu: any) => {
    if (collapseMenu) {
        document.getElementById("main-wrapper")?.classList.toggle("menu-toggle");
        setCollapseMenu(!collapseMenu);
    }
}

export const persistLoginDetail = (coachID: number, coachAPIKey: string, coachName: string, coachPicture: string) => {
    localStorage.setItem('coachID', coachID.toString());
    localStorage.setItem('coachAPIKey', coachAPIKey);
    localStorage.setItem('coachName', coachName);
    localStorage.setItem('coachPicture', coachPicture);
}

export const persistCoachDisplayData = (coachName: string, coachPicture: string) => {
    localStorage.setItem('coachName', coachName);
    localStorage.setItem('coachPicture', coachPicture);
}

export const getCoachProfileDetail = (): UIDataTypes.CoachProfile => {
    const coachIDStr = localStorage.getItem('coachID');
    const coachID = parseInt(coachIDStr != null ? coachIDStr : "0");
    const coachAPIKey = localStorage.getItem('coachAPIKey');
    const coachName = localStorage.getItem('coachName');
    const coachPicture = localStorage.getItem('coachPicture');
    return { coachID: coachID, coachAPIKey: coachAPIKey ? coachAPIKey : '', coachName: coachName ? coachName : '', coachProfilePicture: coachPicture ? coachPicture : '' }
}

export const removeLogin = () => {
    localStorage.removeItem('coachID');
    localStorage.removeItem('coachAPIKey');
    localStorage.removeItem('coachName');
    localStorage.removeItem('coachPicture');
}

export const isCustomerLoggedIn = () => {
    const coachIDStr = localStorage.getItem('coachID');
    const coachID = parseInt(coachIDStr != null ? coachIDStr : "0");
    if (coachID) {
        return true;
    } else {
        return false;
    }
}

export const getAuthString = async () => {
    let basicAuthString = '';

    const authID = localStorage.getItem('coachID');
    const authAPIKey = localStorage.getItem('coachAPIKey');
    if (authID !== null && authAPIKey !== null) {
        basicAuthString = "Basic " + (window.btoa(authID + "#1:" + authAPIKey));
        return basicAuthString;
    }
}

export const convertTimestampToCustom = (date: number, customFormat: string) => moment(date).format(customFormat);
export const convertDateStringToTimestamp = (date: string) => moment(date).valueOf();

export const getFormattedLocalDate = (utcDateTime: string, format: string = DateFormats.DDMMYYYY) => {
    if (utcDateTime !== undefined && utcDateTime !== '' && utcDateTime !== null) {
        return moment.utc(utcDateTime).local().format(format);
    } else {
        return 'N/A';
    }
}

export const getFormattedLocalDateTime = (utcDateTime: string, dateFormat: string = DateFormats.DDMMYYYY, timeFormat: string = 'hh:mm a') => {
    if (utcDateTime && utcDateTime !== '') {
        return moment.utc(utcDateTime).local().format(dateFormat + ' ' + timeFormat);
    }
    return 'N/A';
}

export const getFormattedLocalTime = (utcDateTime: string, timeFormat: string = 'hh:mm a') => {
    if (utcDateTime && utcDateTime !== '') {
        return moment.utc(utcDateTime).local().format(timeFormat);
    }
    return 'N/A';
}

export const formatMinutesToDisplayFormat = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
    const minutesString = remainingMinutes > 0 ? `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : '';
    const separator = hoursString && minutesString ? ' and ' : '';

    return `${hoursString}${separator}${minutesString}`;
}

export const getDurationSince = (dateString: string): string => {
    const date = new Date(dateString);
    //const now = new Date();
    const now = new Date(moment().tz(ProjectConfig.ServerTimezone).format(DateFormats.DDMONYYYY + ' ' + 'hh:mm a'));
    const elapsedMilliseconds = now.getTime() - date.getTime();

    // Calculate the duration in seconds, minutes, hours, and days
    const seconds = Math.floor(elapsedMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
}

export const frameSelectOptions = (data: any[], value_param: string, label_param: string, add_labels?: string[], separator?: string, separatorEnd?: string) => {
    const selectOptions: UIDataTypes.SelectOption[] = data.map((entry) => {
        let labelData = entry[label_param];
        if (add_labels) {
            add_labels.forEach(add_label => {
                if (entry[add_label]) {
                    labelData += (separator ? separator : '-') + entry[add_label] + (separatorEnd ? separatorEnd : '');
                }
            })
        }
        return (
            {
                value: entry[value_param] + "",
                label: labelData
            }
        )
    });
    return selectOptions;
}

export const getDataFromAPI = (apiEndPoint: string, stateSetter: any, addProcessing: any, reduceProcessing: any, params?: any, convertToSelectOptions?: boolean, key?: string, value?: string) => {
    addProcessing();
    ServerAPI.executeAPI(apiEndPoint, ServerAPI.APIMethod.GET, true, null, params).then(response => {
        if (response[apiEndPoint] !== undefined) {
            if (convertToSelectOptions) {
                let options = frameSelectOptions(response[apiEndPoint], key ? key : '', value ? value : '');
                stateSetter(options);
            } else {
                stateSetter(response[apiEndPoint]);
            }

        } else {
            toast.error('Could not fetch ' + apiEndPoint);
        }
    }).finally(() => {
        reduceProcessing();
    });
}

export const convertImageToBase64 = (file: File, callback: (result: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        callback(reader.result);
    };
}

export const getFileTypeIcon = (fileType: string) => {
    if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return window.location.origin + "/assets/images/file_types/xls.png";
    } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return window.location.origin + "/assets/images/file_types/doc.png";
    } else if (fileType === 'application/pdf') {
        return window.location.origin + "/assets/images/file_types/pdf.png";
    } else if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        return window.location.origin + "/assets/images/file_types/ppt.png";
    } else {
        return window.location.origin + "/assets/images/file_types/unknown.png";
    }
}

export const getURLExtension = (url: string) => {
    return url.split(/[#?]/)[0].split('.').pop()?.trim();
}

export const getDisplayImageByURL = (url: string) => {
    const extn = getURLExtension(url);
    if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') {
        return url;
    } else if (extn === 'xls' || extn === 'xlsx' || extn === 'xlsm' || extn === 'xlsb' || extn === 'xltx' || extn === 'csv') {
        return window.location.origin + "/assets/images/file_types/xls.png";
    } else if (extn === 'doc' || extn === 'docx' || extn === 'odt') {
        return window.location.origin + "/assets/images/file_types/doc.png";
    } else if (extn === 'pdf') {
        return window.location.origin + "/assets/images/file_types/pdf.png";
    } else if (extn === 'ppt' || extn === 'pptx' || extn === 'pptm') {
        return window.location.origin + "/assets/images/file_types/ppt.png";
    } else {
        return window.location.origin + "/assets/images/file_types/unknown.png";
    }
}

export const validateYouTubeVideoId = (videoId: string): boolean => {
    const pattern = /^[A-Za-z0-9_-]{11}$/;
    return pattern.test(videoId);
}

export const getListingDaySessionText = (session: number) => {
    let sessionName = "";
    if (session === ListingDaySession.Anytime) {
        sessionName = 'Anytime';
    } else if (session === ListingDaySession.Morning) {
        sessionName = 'Morning';
    } else if (session === ListingDaySession.Afternoon) {
        sessionName = 'Afternoon';
    } else if (session === ListingDaySession.Evening) {
        sessionName = 'Evening';
    } else if (session === ListingDaySession.Night) {
        sessionName = 'Night';
    } else if (session === ListingDaySession.Custom) {
        sessionName = 'Custom';
    }
    return sessionName;
}

export const getListCategoryName = (listCategory: number) => {
    let listCategoryName = "";
    if (listCategory === ListCategory.ByteSizeChallenge) {
        listCategoryName = "Byte Size Challenge";
    } else if (listCategory === ListCategory.Program) {
        listCategoryName = "Program";
    } else if (listCategory === ListCategory.LiveSession) {
        listCategoryName = "Live Session";
    } else if (listCategory === ListCategory.Workshop) {
        listCategoryName = "Workshop";
    } else if (listCategory === ListCategory.NewsArticle) {
        listCategoryName = "New Article";
    }
    return listCategoryName;
}

export const getListingStatusText = (listingStatus: number) => {
    let listingStatusName = '';
    if (listingStatus === ListingStatus.Draft) {
        listingStatusName = 'Draft';
    } else if (listingStatus === ListingStatus.Submitted) {
        listingStatusName = 'Submitted';
    } else if (listingStatus === ListingStatus.UnderReview) {
        listingStatusName = 'Under Review';
    } else if (listingStatus === ListingStatus.Rejected) {
        listingStatusName = 'Rejected';
    } else if (listingStatus === ListingStatus.Published) {
        listingStatusName = 'Published';
    } else if (listingStatus === ListingStatus.Open) {
        listingStatusName = 'Open';
    } else if (listingStatus === ListingStatus.Closed) {
        listingStatusName = 'Closed';
    } else if (listingStatus === ListingStatus.Completed) {
        listingStatusName = 'Completed';
    } else if (listingStatus === ListingStatus.OnHold) {
        listingStatusName = 'On Hold';
    } else if (listingStatus === ListingStatus.Terminated) {
        listingStatusName = 'Terminated';
    }
    return listingStatusName;
}

export const getListingModeString = (mode: number) => {
    let modeString = '';
    if (mode === ListingMode.Offline) {
        modeString = 'Offline';
    } else {
        modeString = 'Online';
    }
    return modeString;
}

export const getMaritalStatusString = (maritalStatus: number) => {
    let maritalString = '';
    if (maritalStatus === MaritalStatus.Single) {
        maritalString = 'Single';
    } else if (maritalStatus === MaritalStatus.Married) {
        maritalString = 'Married';
    } else if (maritalStatus === MaritalStatus.Divorced) {
        maritalString = 'Divorced';
    } else if (maritalStatus === MaritalStatus.Separated) {
        maritalString = 'Separated';
    } else if (maritalStatus === MaritalStatus.Widowed) {
        maritalString = 'Widowed';
    }
    return maritalString;
}

export const getGenderString = (gender: number) => {
    let genderString = '';
    if (gender === Gender.Male) {
        genderString = 'Male';
    } else if (gender === Gender.Female) {
        genderString = 'Female';
    } else if (gender === Gender.Transgender) {
        genderString = 'Transgender';
    } else if (gender === Gender.NonBinary) {
        genderString = 'NonBinary';
    }
    return genderString;
}

export const getBankAccountTypeString = (accountType: number) => {
    let accTypeString = '';
    if (accountType === BankAccountType.Savings) {
        accTypeString = 'Savings';
    } else if (accountType === BankAccountType.Current) {
        accTypeString = 'Current';
    }
    return accTypeString;
}

export const getMetricInputTypeString = (inputType: number) => {
    let metricInputTypeString = '';
    if (inputType === MetricInputType.Number) {
        metricInputTypeString = 'Number';
    } else if (inputType === MetricInputType.Text) {
        metricInputTypeString = 'Text';
    }
    return metricInputTypeString;
}

export const convertFileToBase64 = async (file: any) => {
    const response = await fetch(file.webPath!);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.readAsDataURL(blob);
    });
};

export const getCustomerProfileImage = (profileImage: string | undefined) => {
    return (profileImage !== '') ? profileImage : window.location.origin +  "/assets/images/default-avatar.png"
}