const ActionViewPayouts: React.FC<{ data: any, handlers: any }> = ({ data, handlers }) => {

    return (
        <button type="button" className="btn btn-no-style action-icon-btn" onClick={() => {
            if(handlers !== undefined) {
                handlers.showPayouts(data['listing_purchase_id'])
            }
        }}>
            <i className={"fa-solid fa-eye"}></i>
        </button>
    )

}

export default ActionViewPayouts;