import { useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../components/ui_components/BackButton";
import { useEffect, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { toast } from "react-hot-toast";
import { CustomerByteChallengeStatus, DateFormats, ListCategory, ListingDaySession, ListingPurchaseProgressStatus } from "../common/Constants";
import { getCustomerProfileImage, getFormattedLocalDate, getFormattedLocalDateTime, getListingDaySessionText } from "../common/Utilities";
import MetricBarChart from "../components/listing_detail/MetricBarChart";
import StarRating from "../components/ui_components/StarRating";

const ParticipantDetail: React.FC = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [listingPurchaseID, setListingPurchaseID] = useState(0);
    const [listingPurchase, setListingPurchase] = useState<APIData.ListingPurchase>();
    const [challengeCompleteTime, setChallengeCompleteTime] = useState('');
    const [coachResponseText, setCoachResponseText] = useState('');
    // const [metricIDs, setMetricIDs] = useState<number[]>([]);
    // const [metricNames, setMetricNames] = useState<string[]>([]);

    const loadListingPurchase = () => {
        if (listingPurchaseID) {
            addProcessingRequests();
            ServerAPI.getListingPurchase(listingPurchaseID).then(response => {
                if (response.listing_purchases !== undefined) {
                    const listingPur: APIData.ListingPurchase = response.listing_purchases[0];
                    setListingPurchase(listingPur);
                    if (listingPur.listing_purchase_progress_statuses !== undefined) {
                        const challengeCompleteStatus = listingPur.listing_purchase_progress_statuses.filter(status => status.listing_purchase_progress_status == CustomerByteChallengeStatus.Submitted);
                        if (challengeCompleteStatus.length > 0) {
                            setChallengeCompleteTime(challengeCompleteStatus[0].listing_purchase_progress_status_time);
                        }
                    }
                    if (listingPur.customer_byte_challenges !== undefined && listingPur.customer_byte_challenges.length > 0) {
                        setCoachResponseText(listingPur.customer_byte_challenges[0].customer_byte_challenge_coach_feedback);
                    }

                    /*if (listingPur.customer_program_metric_updates !== undefined && listingPur.customer_program_metric_updates.length > 0) {
                        let uniqueMetricIDs: number[] = [];
                        let uniqueMetricNames: string[] = [];
                        listingPur.customer_program_metric_updates.forEach((update, updateIdx) => {
                            if (update.customer_program_metric_values !== undefined) {
                                update.customer_program_metric_values.forEach((value, valIdx) => {
                                    if (!uniqueMetricIDs.includes(value.listing_program_metric_id)) {
                                        uniqueMetricIDs.push(value.listing_program_metric_id);
                                        uniqueMetricNames.push(value.listing_program_metric_name);
                                    }
                                })
                            }
                        })
                        setMetricIDs(uniqueMetricIDs);
                        setMetricNames(uniqueMetricNames);
                    }*/
                } else {
                    toast.error('Could not fetch Listing Detail');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const handleSubmit = () => {
        if (!coachResponseText) {
            toast.error('Enter a response Text!');
        } else {
            if (listingPurchase !== undefined && listingPurchase.customer_byte_challenges !== undefined) {
                const challenge = listingPurchase.customer_byte_challenges[0];
                addProcessingRequests();
                ServerAPI.updateByteSizeCoachResponse(challenge.customer_byte_challenge_id, coachResponseText).then((response) => {
                    if (response.success) {
                        toast.success(response.message);
                        navigate(-1);
                    } else {
                        toast.error(response.message);
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    useEffect(() => {
        const paramListingID = searchParams.get('id');
        if (paramListingID) {
            setListingPurchaseID(parseInt(paramListingID));
        }
    }, [searchParams])

    useEffect(() => {
        loadListingPurchase();
    }, [listingPurchaseID]);

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <BackButton />
                            </div>
                            {listingPurchase !== undefined && (
                                <div className="card-body">
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img src={getCustomerProfileImage(listingPurchase.customer_profile_image)} className="listing-profile-image" />
                                            </div>
                                            <div className="ml-3">
                                                <p className="big-bold-purple-text mb-0">{listingPurchase.customer_name}</p>
                                                <p className="small-sub-custom-text mb-0">{listingPurchase.customer_email}</p>
                                                <p className="small-sub-custom-text mb-0">{listingPurchase.customer_mobile}</p>
                                                <p className="small-sub-custom-text mb-0">Points Earned: {listingPurchase.customer_points_earned}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 listing-details-box-display">
                                        <div className="listing-details-box mr-4">
                                            <img src={listingPurchase.category_app_icon} className="detox-icon" />
                                            <div className="ml-2">
                                                <p className="medium-info mb-0">{listingPurchase.listing_title}</p>
                                                <p className="detox-effects mb-0">{listingPurchase.vertical_name} &gt; {listingPurchase.sub_vertical_name} | {listingPurchase.category_name}</p>
                                            </div>
                                        </div>
                                        <div className="listing-details-box justify-content-between">
                                            {challengeCompleteTime !== '' && (
                                                <>
                                                    <div className="ml-2">
                                                        <p className="small-text-heading mb-0">Date & Time</p>
                                                        <p className="big-bold-purple-text mb-0">{getFormattedLocalDateTime(challengeCompleteTime, DateFormats.DDMONYYYY)}</p>
                                                        <p className="bold-medium-grey">{getFormattedLocalDate(challengeCompleteTime, 'dddd')}</p>
                                                    </div>
                                                    <img src={window.location.origin + "/assets/images/card/calender-empty.png"} className="calender-icon" />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {listingPurchase.customer_program_metric_updates !== undefined && listingPurchase.customer_program_metric_updates.length > 0 && (
                                        <>
                                            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12  col-xs-12 p-0 bar_chart_box">
                                                <MetricBarChart metric_updates={listingPurchase.customer_program_metric_updates} />
                                            </div>
                                            {/* <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-responsive-md my_listing">
                                                        <thead>
                                                            <tr>
                                                                <th className="width80">Date</th>
                                                                {metricNames.map((metricName, index) => <th key={index}>{metricName}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listingPurchase.customer_program_metric_updates.map((metricUpdate, updateIdx) => (
                                                                <tr key={updateIdx}>
                                                                    <td>{getFormattedLocalDateTime(metricUpdate.customer_program_metric_update_time)}</td>
                                                                    {metricUpdate.customer_program_metric_values !== undefined && metricIDs.map((metricID, valueIdx) => {
                                                                        const matchingValues = metricUpdate.customer_program_metric_values?.filter((value => value.listing_program_metric_id === metricID));
                                                                        return (
                                                                            <td key={valueIdx}>
                                                                                {matchingValues !== undefined && matchingValues?.length > 0 ? matchingValues[0].customer_program_metric_value : 'N/A'}
                                                                            </td>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> */}
                                        </>
                                    )}
                                    {listingPurchase.listing_purchase_progress_status >= ListingPurchaseProgressStatus.Submitted && listingPurchase.customer_byte_challenges !== undefined && listingPurchase.customer_byte_challenges.map((challenge, chIdx) => (
                                        <>
                                            {challenge.customer_byte_tasks !== undefined && challenge.customer_byte_tasks.map((task, taskIdx) => (
                                                <div className="mt-3" key={chIdx + '-' + taskIdx}>
                                                    <p className="big-bold-purple-text mb-1">Task - {taskIdx + 1}</p>
                                                    <div className="listing-details-box w-100">
                                                        <div className="ml-2">
                                                            {/* <p className="small-text-heading-2 mb-0">Task 1</p> */}
                                                            <p className="detox-timings mb-0">Day {task.customer_byte_task_day}
                                                                {task.customer_byte_task_session != ListingDaySession.Anytime && task.customer_byte_task_session !== ListingDaySession.Custom && (' | ' + getListingDaySessionText(task.customer_byte_task_session) + ' Session')}{task.customer_byte_task_session === ListingDaySession.Custom && (' | ' + getFormattedLocalDateTime(task.customer_byte_task_updated_datetime))}
                                                            </p>
                                                            <p className="medium-small-grey">{task.customer_byte_task_description}</p>
                                                        </div>
                                                    </div>
                                                    {task.customer_byte_task_photos !== undefined && task.customer_byte_task_photos.length > 0 && (
                                                        <>
                                                            <div className="mt-2"></div>
                                                            <p className="images-heading-text mb-2">Images</p>
                                                            <div className="d-flex">
                                                                {task.customer_byte_task_photos.map((photo, index) => <img src={photo.customer_byte_task_photo} className="participant-task-images" key={index} />)}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                            <div className="mt-3">
                                                <p className="images-heading-text mb-2">Participants Comment</p>
                                                <textarea id="w3review" name="w3review" rows={4} cols={100} className="comment-area" readOnly={true}>{challenge.customer_byte_challenge_comment}</textarea>
                                            </div>
                                            <div className="mt-3">
                                                <p className="images-heading-text mb-2">Coach Feedback</p>
                                                <textarea id="w3review" name="w3review" rows={4} cols={100} className="comment-area" value={coachResponseText} onChange={(e) => setCoachResponseText(e.target.value)} readOnly={challenge.customer_byte_challenge_status === CustomerByteChallengeStatus.Completed} />
                                            </div>
                                            {listingPurchase.listing_feedback_id !== 0 && listingPurchase.listing_feedback_rating !== undefined && (
                                                <div className="mt-3">
                                                    <p className="big-bold-purple-text mb-1">Participants Feedback</p>
                                                    <div className="participants-feedbacks w-100">
                                                        <div className="ml-2 participants-feedbacks-sub-sec">
                                                            <p className="small-text-heading-2 mb-0">Overall Experience</p>
                                                            <StarRating rating={listingPurchase.listing_feedback_rating} />
                                                        </div>
                                                        {listingPurchase.listing_feedback_text !== undefined && listingPurchase.listing_feedback_text !== '' && (
                                                            <div className="ml-2 participants-feedbacks-sub-sec">
                                                                <p className="small-text-heading-2 mb-0">Feedback</p>
                                                                <p className="medium-small-grey">{listingPurchase.listing_feedback_text}</p>
                                                            </div>
                                                        )}
                                                        {listingPurchase.listing_feedback_photos !== undefined && listingPurchase.listing_feedback_photos.length > 0 && (
                                                            <div className="ml-2 participants-feedbacks-sub-sec">
                                                                <div className="mt-2"></div>
                                                                <p className="images-heading-text mb-2">Images</p>
                                                                <div className="customer_uploads">
                                                                    <div className="customer_uploads_uploaded_image">
                                                                        {listingPurchase.listing_feedback_photos.map((photo, index) =>
                                                                            <div className="customer_uploads_uploaded_image_img" key={index}>
                                                                                <img src={photo.listing_feedback_photo} className="customer_upload" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-4 confirmation-buttons-end">
                                                <button className="btn-bgNone" onClick={() => navigate(-1)}>{challenge.customer_byte_challenge_status === CustomerByteChallengeStatus.Completed ? 'Close' : 'Cancel'}</button>
                                                {challenge.customer_byte_challenge_status === CustomerByteChallengeStatus.Submitted && (
                                                    <button type="submit" className="bg-pink primary_btn text-primary btn-add-new btn-custom-purple ml-3" onClick={handleSubmit}>Submit</button>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                    {(listingPurchase.listing_purchase_progress_status < ListingPurchaseProgressStatus.Completed || listingPurchase.category_id !== ListCategory.ByteSizeChallenge) && (
                                        <div className="mt-4 confirmation-buttons-end">
                                            <button className="btn-bgNone" onClick={() => navigate(-1)}>Close</button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipantDetail;
