import { Fragment, useEffect, useRef, useState } from "react";
import { API, ListCategory, ListingDaySession, ListingMode, ProjectStrings } from "../common/Constants";
import PopupListingCategorySelect from "../components/popups/PopupListingCategorySelect";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getDataFromAPI, getListCategoryName } from "../common/Utilities";
import FormField from "../components/form/FormField";
import { FieldArray, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { APIData, UIDataTypes } from "../common/DataTypes";
import { InitialData } from "../common/InitialData";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import moment from "moment";

const ListingEntry: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const subArrStrTask = "draft_listing_byte_tasks";
    const subArrStrSession = "draft_listing_program_sessions";

    const [showCategoryPopup, setShowCategoryPopup] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [initialValue, setInitialValue] = useState<APIData.DraftListingEntry>(InitialData.DraftListingEntry);

    const modeOptions: UIDataTypes.SelectOption[] = [{ label: "Offline", value: ListingMode.Offline }, { label: "Online", value: ListingMode.Online }];
    const byteDaySessionOptions: UIDataTypes.SelectOption[] = [
        { label: "Anytime", value: ListingDaySession.Anytime },
        { label: "Morning", value: ListingDaySession.Morning },
        { label: "Afternoon", value: ListingDaySession.Afternoon },
        { label: "Evening", value: ListingDaySession.Evening },
        { label: "Night", value: ListingDaySession.Night },
        { label: "Custom", value: ListingDaySession.Custom },
    ];

    const [byteDaysOptions, setByteDaysOptions] = useState<UIDataTypes.SelectOption[]>([]);

    const setInitValues = (verticalID: number, subVerticalID: number, categoryID: number) => {
        setCategoryID(categoryID);
        setInitialValue({ ...initialValue, category_id: categoryID, sub_vertical_id: subVerticalID });
        setShowCategoryPopup(false);
    }

    const cancelOperation = () => {
        navigate(-1)
    }

    const loadDraftListing = () => {
        const paramDraftListingID = searchParams.get('id');
        if (paramDraftListingID !== null) {
            const draftListingID = parseInt(paramDraftListingID);
            addProcessingRequests();
            ServerAPI.getDraftListing(draftListingID).then(response => {
                if (response.draft_listings !== undefined) {
                    const draftListing: APIData.DraftListing = response.draft_listings[0];
                    setCategoryID(draftListing.category_id);
                    setInitialValue({
                        draft_listing_id: draftListing.draft_listing_id,
                        category_id: draftListing.category_id,
                        sub_vertical_id: draftListing.sub_vertical_id,
                        listing_title: draftListing.listing_title,
                        listing_banner_image: draftListing.listing_banner_image,
                        listing_about_video_url: draftListing.listing_about_video_url,
                        listing_description: draftListing.listing_description,
                        listing_scientific_justification: draftListing.listing_scientific_justification,
                        listing_expected_outcome: draftListing.listing_expected_outcome,
                        listing_reward_points: draftListing.listing_reward_points,
                        listing_price: draftListing.listing_price,
                        listing_batch_size: draftListing.listing_batch_size,
                        listing_start_datetime: draftListing.listing_start_datetime,
                        listing_duration: draftListing.listing_duration,
                        listing_mode: draftListing.listing_mode,
                        listing_venue: draftListing.listing_venue,
                        listing_online_link: draftListing.listing_online_link,
                        //listing_badge_template_image: draftListing.listing_badge_template_image,
                        listing_submit_for_review: 0,
                        draft_listing_byte_tasks: draftListing.draft_listing_byte_tasks,
                        draft_listing_program_sessions: draftListing.draft_listing_program_sessions,
                    });
                } else {
                    toast.error('Could not fetch Sub Verticals');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    useEffect(() => {
        getDataFromAPI(API.EndPoint.ByteSizeDays, setByteDaysOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'byte_size_day_id', 'byte_size_day_name');
    }, [])

    useEffect(() => {
        if (searchParams.has('id')) {
            loadDraftListing();
        } else {
            setShowCategoryPopup(true);
        }
    }, [location]);

    const formikRef = useRef<FormikProps<APIData.DraftListingEntry>>(null);

    const isValidYoutubeID = (value: any) => {
        if (typeof value !== 'string') return false;
        return (/^[a-zA-Z0-9_-]{11}$/).test(value);
    };

    const yupSchema = Yup.object().shape({
        category_id: Yup.number().required(ProjectStrings.ValidationRequired),
        listing_title: Yup.string().required(ProjectStrings.ValidationRequired),
        listing_banner_image: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { listing_banner_image_base64 } = this.parent;
            if (!listing_banner_image_base64 && !value) {
                return false;
            }
            return true;
        }),
        listing_about_video_url: Yup.string()
            .test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
                const { category_id } = this.parent;
                if ((category_id === ListCategory.ByteSizeChallenge || category_id === ListCategory.Program) && !value) {
                    return false;
                }
                return true;
            }).test('is-valid-youtube-id', 'Enter valid Youtube Video ID', function (value) {
                const { category_id } = this.parent;
                if ((category_id === ListCategory.ByteSizeChallenge || category_id === ListCategory.Program) && !isValidYoutubeID(value)) {
                    return false;
                }
                return true;
            }),

        listing_batch_size: Yup.number().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.Program || category_id === ListCategory.LiveSession || category_id === ListCategory.Workshop) && !value) {
                return false;
            }
            return true;
        }),
        listing_price: Yup.number().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.Program || category_id === ListCategory.LiveSession || category_id === ListCategory.Workshop) && !value) {
                return false;
            }
            return true;
        }),
        listing_duration: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.LiveSession || category_id === ListCategory.Workshop) && !value) {
                return false;
            }
            return true;
        }),
        listing_mode: Yup.number().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.LiveSession || category_id === ListCategory.Workshop) && !value) {
                return false;
            }
            return true;
        }),
        listing_venue: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { listing_mode } = this.parent;
            if (listing_mode === ListingMode.Offline && !value) {
                return false;
            }
            return true;
        }),
        listing_online_link: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { listing_mode, category_id } = this.parent;
            if ((listing_mode === ListingMode.Online || category_id === ListCategory.NewsArticle) && !value) {
                return false;
            }
            return true;
        }),
        listing_start_datetime: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.LiveSession || category_id === ListCategory.Workshop) && !value) {
                return false;
            }
            return true;
        }),
        listing_description: Yup.string().required(ProjectStrings.ValidationRequired),
        listing_expected_outcome: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.ByteSizeChallenge || category_id === ListCategory.Program) && !value) {
                return false;
            }
            return true;
        }),
        listing_scientific_justification: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
            const { category_id } = this.parent;
            if ((category_id === ListCategory.ByteSizeChallenge || category_id === ListCategory.Program) && !value) {
                return false;
            }
            return true;
        }),
        draft_listing_byte_tasks: Yup.array()
            .of(
                Yup.object().shape({
                    listing_byte_task_schedule_day: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
                    listing_byte_task_schedule_session: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
                    listing_byte_task_schedule_custom_time: Yup.string().test('conditional-required', ProjectStrings.ValidationRequired, function (value) {
                        const { listing_byte_task_schedule_session } = this.parent;
                        if (listing_byte_task_schedule_session === ListingDaySession.Custom && !value) {
                            return false;
                        }
                        return true;
                    }),
                    listing_byte_task_description: Yup.string().required(ProjectStrings.ValidationRequired),
                })
            ),
        draft_listing_program_sessions: Yup.array()
            .of(
                Yup.object().shape({
                    listing_program_session_title: Yup.string().required(ProjectStrings.ValidationRequired),
                    listing_program_session_datetime: Yup.string().required(ProjectStrings.ValidationRequired),
                    listing_program_session_mode: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
                    listing_program_session_duration_mins: Yup.number().required(ProjectStrings.ValidationRequired),
                    listing_program_session_description: Yup.string().required(ProjectStrings.ValidationRequired),
                })
            )
    });

    let submitForReview = false;

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title text-dark-gray">Add {getListCategoryName(categoryID)}</h4>
                            </div>
                            <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                                let submitValues = { ...values };
                                delete submitValues.listing_banner_image_file;
                                //delete submitValues.listing_badge_template_image_file;
                                if (submitForReview) {
                                    submitValues.listing_submit_for_review = 1;
                                }
                                if (submitValues.listing_start_datetime) {
                                    submitValues.listing_start_datetime = moment(submitValues.listing_start_datetime).format('YYYY-MM-DD HH:mm:ss');
                                }
                                console.log(values);
                                if (submitValues.draft_listing_id > 0) {
                                    addProcessingRequests();
                                    ServerAPI.updateDraftListing(submitValues.draft_listing_id, values).then(response => {
                                        if (response.success) {
                                            toast.success(response.message);
                                            setSubmitting(false);
                                            navigate(-1);
                                        } else {
                                            toast.error(response.message);
                                        }
                                    }).finally(() => {
                                        reduceProcessingRequests();
                                    });
                                } else {
                                    addProcessingRequests();
                                    ServerAPI.addDraftListing(values).then(response => {
                                        if (response.success) {
                                            toast.success(response.message);
                                            setSubmitting(false);
                                            navigate(-1);
                                        } else {
                                            toast.error(response.message);
                                        }
                                    }).finally(() => {
                                        reduceProcessingRequests();
                                    });
                                }
                            }}>
                                {(formikProps) => {
                                    return (
                                        <div className="card-body card-body-padding">
                                            <div className="d-flex card-body-elements">
                                                <div className="w-35 mr-5">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_title", label: "Heading", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                </div>
                                                <div className="w-35">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "listing_banner_image", label: "Banner Image", className: "popup-textbox banner-image-upload-box pl-5", accept: "image/*", required: true }} />
                                                </div>
                                            </div>
                                            {categoryID === ListCategory.ByteSizeChallenge && (
                                                <>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_reward_points", label: "Reward Points", className: "popup-textbox", placeholder: "Type Here" }} />
                                                        </div>
                                                        <div className="w-35">
                                                            <div className="mb-3">
                                                                <p className="mb-1 popup-subheadings">Price in Ringgit</p>
                                                                <input type="text" className="popup-textbox" value="Free" disabled={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_about_video_url", label: "Youtube Video ID", className: "popup-textbox", required: true, placeholder: "Enter Video ID" }} />
                                                        </div>
                                                        {/* <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "listing_badge_template_image", label: "Badge Template Image", className: "popup-textbox banner-image-upload-box pl-5", accept: "image/*" }} />
                                                        </div> */}
                                                    </div>
                                                </>
                                            )}
                                            {categoryID === ListCategory.Program && (
                                                <>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_reward_points", label: "Reward Points", className: "popup-textbox", placeholder: "Type Here" }} />
                                                        </div>
                                                        <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_price", label: "Price In Ringgit", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_about_video_url", label: "Youtube Video ID", className: "popup-textbox", required: true, placeholder: "Enter URL" }} />
                                                        </div>
                                                        {/* <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "listing_badge_template_image", label: "Badge Template Image", className: "popup-textbox banner-image-upload-box pl-5", accept: "image/*" }} />
                                                        </div> */}
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_batch_size", label: "Batch Size", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {(categoryID === ListCategory.Workshop || categoryID === ListCategory.LiveSession) && (
                                                <>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_reward_points", label: "Reward Points", className: "popup-textbox", placeholder: "Type Here" }} />
                                                        </div>
                                                        <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_price", label: "Price In Ringgit", className: "popup-textbox", required: true }} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "duration", name: "listing_duration", label: "Duration", className: "popup-textbox", required: true }} />
                                                        </div>
                                                        <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "listing_batch_size", label: "Batch Size", className: "popup-textbox", required: true }} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "listing_mode", label: "Mode", className: "popup-textbox", options: modeOptions, required: true }} />
                                                        </div>
                                                        <div className="w-35">
                                                            {formikProps.values.listing_mode === ListingMode.Offline && (
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_venue", label: "Venue", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                            )}
                                                            {formikProps.values.listing_mode === ListingMode.Online && (
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_online_link", label: "Online Link", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex card-body-elements">
                                                        <div className="w-35 mr-5">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetime", name: "listing_start_datetime", label: "Start DateTime", required: true, className: "popup-textbox" }} />
                                                        </div>
                                                        {/* <div className="w-35">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "listing_badge_template_image", label: "Badge Template Image", className: "popup-textbox banner-image-upload-box pl-5", accept: "image/*" }} />
                                                        </div> */}
                                                    </div>
                                                </>
                                            )}
                                            {categoryID === ListCategory.NewsArticle && (
                                                <div>
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_online_link", label: "Article Link", className: "popup-textbox", required: true }} />
                                                </div>
                                            )}
                                            <div className="w-100 card-body-elements">
                                                <FormField formik={formikProps} fieldProps={{
                                                    fieldType: "textarea",
                                                    name: "listing_description",
                                                    label: (categoryID === ListCategory.ByteSizeChallenge) ? "Describe the Challege" : getListCategoryName(categoryID) + " Description",
                                                    className: "comment-area",
                                                    required: true,
                                                    rows: 4,
                                                    cols: 100
                                                }} />
                                            </div>
                                            {(categoryID === ListCategory.ByteSizeChallenge || categoryID === ListCategory.Program) && (
                                                <>
                                                    <div className="w-100 card-body-elements">
                                                        <FormField formik={formikProps} fieldProps={{
                                                            fieldType: "textarea",
                                                            name: "listing_expected_outcome",
                                                            label: "Expected Outcome",
                                                            className: "comment-area",
                                                            required: true,
                                                            rows: 4,
                                                            cols: 100
                                                        }} />
                                                    </div>
                                                    <div className="w-100 card-body-elements">
                                                        <FormField formik={formikProps} fieldProps={{
                                                            fieldType: "textarea",
                                                            name: "listing_scientific_justification",
                                                            label: "Scientific Explanation",
                                                            className: "comment-area",
                                                            required: true,
                                                            rows: 4,
                                                            cols: 100
                                                        }} />
                                                    </div>
                                                </>
                                            )}
                                            <div className="bb-line"></div>
                                            {(categoryID === ListCategory.ByteSizeChallenge) && (

                                                <FieldArray name={subArrStrTask}>
                                                    {({ insert, remove, push }) => (
                                                        <>
                                                            <div className="d-flex align-items-center mb-4">
                                                                <h4 className="card-title text-dark-gray mr-4 mb-0">Add Task</h4>
                                                                <button className="btn-primary-small" onClick={() => push(InitialData.ListingByteTask)}>Add More</button>
                                                            </div>
                                                            {formikProps.values[subArrStrTask] != undefined && formikProps.values[subArrStrTask].length > 0 &&
                                                                <>
                                                                    {formikProps.values[subArrStrTask].map((data, index) => {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <p className="section-sub-heading-inter mb-0">Task {index + 1}</p>
                                                                                    <button className="btn-close-red ml-2" onClick={() => remove(index)}><p>X</p></button>
                                                                                </div>
                                                                                <div className="d-flex card-body-elements">
                                                                                    <div className="w-35 mr-5">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: subArrStrTask + "." + index + ".listing_byte_task_schedule_day", label: "Day", className: "popup-textbox", options: byteDaysOptions, required: true }} />
                                                                                    </div>
                                                                                    <div className="w-35">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: subArrStrTask + "." + index + ".listing_byte_task_schedule_session", label: "Session", className: "popup-textbox", options: byteDaySessionOptions, required: true }} />
                                                                                    </div>
                                                                                </div>
                                                                                {data.listing_byte_task_schedule_session === ListingDaySession.Custom && (
                                                                                    <div className="card-body-elements">
                                                                                        <div className="w-35 mr-5">
                                                                                            <div className="mb-3">
                                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "time", name: subArrStrTask + "." + index + ".listing_byte_task_schedule_custom_time", label: "Time", className: "popup-textbox", required: true }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <div className="w-100 card-body-elements">
                                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", name: subArrStrTask + "." + index + ".listing_byte_task_description", label: "Task Description", className: "comment-area", rows: 4, cols: 100, required: true }} />
                                                                                </div>
                                                                                <div className="bb-line"></div>
                                                                            </Fragment>
                                                                        )
                                                                    })}
                                                                </>
                                                            }
                                                        </>
                                                    )}
                                                </FieldArray>
                                            )}
                                            {categoryID === ListCategory.Program && (
                                                <FieldArray name={subArrStrSession}>
                                                    {({ insert, remove, push }) => (
                                                        <>
                                                            <div className="d-flex align-items-center mb-4">
                                                                <h4 className="card-title text-dark-gray mr-4 mb-0">Add Sessions</h4>
                                                                <button className="btn-primary-small" onClick={() => push(InitialData.ListingProgramSession)}>Add More</button>
                                                            </div>
                                                            {formikProps.values[subArrStrSession] != undefined && formikProps.values[subArrStrSession].length > 0 &&
                                                                <>
                                                                    {formikProps.values[subArrStrSession].map((data, index) => {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <p className="section-sub-heading-inter mb-0">Sessions {index + 1}</p>
                                                                                    <button className="btn-close-red ml-2" onClick={() => remove(index)}><p>X</p></button>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="w-35 mr-5">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: subArrStrSession + "." + index + ".listing_program_session_title", label: "Title", className: "popup-textbox", required: true }} />
                                                                                    </div>
                                                                                    <div className="w-35 mr-5">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "datetime", name: subArrStrSession + "." + index + ".listing_program_session_datetime", label: "Start Date Time", className: "popup-textbox", required: true }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="w-35 mr-5">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: subArrStrSession + "." + index + ".listing_program_session_mode", label: "Mode", className: "popup-textbox", options: modeOptions, required: true }} />
                                                                                    </div>
                                                                                    <div className="w-35 mr-5">
                                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "duration", name: subArrStrSession + "." + index + ".listing_program_session_duration_mins", label: "Duration", className: "popup-textbox", required: true }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className=" w-100">
                                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", name: subArrStrSession + "." + index + ".listing_program_session_description", label: "Task Description", className: "popup-textbox", required: true, rows: 4, cols: 100 }} />
                                                                                </div>
                                                                                <div className="bb-line"></div>
                                                                            </Fragment>
                                                                        )
                                                                    })}
                                                                </>
                                                            }
                                                        </>
                                                    )}
                                                </FieldArray>
                                            )}
                                            <div className="mt-4 confirmation-buttons-end">
                                                <button className="btn-bgNone" onClick={() => navigate(-1)}>Cancel</button>
                                                <button type="submit" className="bg-pink primary_btn text-primary btn-add-new btn-custom-purple ml-3" onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Save as Draft</button>
                                                <button type="submit" className="bg-pink primary_btn text-primary btn-add-new btn-custom-purple bg-green ml-3" onClick={() => {
                                                    formikProps.setFieldValue('listing_submit_for_review', 1);
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit for Review</button>
                                            </div>
                                        </div>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <PopupListingCategorySelect isOpen={showCategoryPopup} closeModel={cancelOperation} setValues={setInitValues} />
            </div>
        </div>
    );
}

export default ListingEntry;