import { Link, useLocation } from "react-router-dom";
import { UIDataTypes } from "../../common/DataTypes";

const HeadSectionTabs: React.FC<{ tabs: UIDataTypes.LinkTab[] }> = ({ tabs }) => {

    const location = useLocation();

    return (
        <div className="section-tabs">
            {tabs.map((tab, tabIdx) => {
                return (
                    <div key={tabIdx}>
                        <Link to={tab.link}>
                            <div className={location.pathname == tab.link ? "section-tab-purple" : "section-tab"}>
                                <p className="section-tabs-heading">{tab.title}</p>
                            </div>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

export default HeadSectionTabs;