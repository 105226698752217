import { DateFormats, ListingMode, TimeFormats } from "../../common/Constants"
import { APIData } from "../../common/DataTypes"
import { formatMinutesToDisplayFormat, getFormattedLocalDate, getFormattedLocalDateTime, getFormattedLocalTime, getListCategoryName, getListingModeString } from "../../common/Utilities"


const SessionWorkshopDetail: React.FC<{ listingDetail: APIData.GeneralListing }> = ({ listingDetail }) => {

    return (
        <>
            <div className="d-flex">
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Heading</p>
                        <p className="points-results">{listingDetail.listing_title}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Date</p>
                        <p className="points-results">{getFormattedLocalDate(listingDetail.listing_start_datetime, DateFormats.DDMONYYYY)}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Mode</p>
                        <p className="points-results">{getListingModeString(listingDetail.listing_mode)}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Price in Ringgit</p>
                        <p className="points-results">{listingDetail.listing_price > 0 ? listingDetail.listing_price : 'Free'}</p>
                    </div>
                </div>
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Vertical</p>
                        <p className="points-results">{listingDetail.vertical_name} &gt; {listingDetail.sub_vertical_name}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Time</p>
                        <p className="points-results">{getFormattedLocalTime(listingDetail.listing_start_datetime, TimeFormats.H12MIN_AMPM)}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Batch Size</p>
                        <p className="points-results">{listingDetail.listing_batch_size}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Last updated Date & Time</p>
                        <p className="points-results">{getFormattedLocalDateTime(listingDetail.listing_start_datetime, DateFormats.DDMONYYYY, TimeFormats.H12MIN_AMPM)}</p>
                    </div>
                </div>
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Category</p>
                        <p className="points-results">{listingDetail.category_name}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Duration</p>
                        <p className="points-results">{formatMinutesToDisplayFormat(listingDetail.listing_duration)}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Reward Points</p>
                        <p className="points-results">{listingDetail.listing_reward_points}</p>
                    </div>
                </div>
            </div>
            <div className="bb-line"></div>
            <div className=" w-100">
                {listingDetail.listing_mode == ListingMode.Online && (
                    <div>
                        <p className="medium-small-grey mb-0">Online Link</p>
                        <p className="points-results">{listingDetail.listing_online_link}</p>
                    </div>
                )}
                {listingDetail.listing_mode == ListingMode.Offline && (
                    <div>
                        <p className="medium-small-grey mb-0">Venue</p>
                        <p className="points-results">{listingDetail.listing_venue}</p>
                    </div>
                )}
            </div>
            <div className=" w-100">
                <div>
                    <p className="medium-small-grey mb-0">Describe the {getListCategoryName(listingDetail.category_id)}</p>
                    <p className="points-results">{listingDetail.listing_expected_outcome}</p>
                </div>
            </div>
        </>
    )
}

export default SessionWorkshopDetail;