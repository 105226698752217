import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import TableMain from "../table/TableMain";
import { TableMappings } from "../../common/TableMappings";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";


const PopupViewPayouts: React.FC<{ showModal: boolean, closeModal: any, listingPurchaseID: number }> = ({ showModal, closeModal, listingPurchaseID }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [tableRows, setTableRows] = useState<any[]>([]);

    const loadData = () => {
        addProcessingRequests();
        ServerAPI.getListingPurchaseCoachPayouts(listingPurchaseID).then(response => {
            if (response.success) {
                if (response.listing_purchase_coach_payouts) {
                    setTableRows(response.listing_purchase_coach_payouts);
                }
            } else {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        loadData();
    }, [listingPurchaseID])

    return (
        <Modal open={showModal} onClose={() => closeModal()} center>
            <div className="card-body">
                <TableMain tableDetail={TableMappings.Payouts} tableRows={tableRows} totalPages={1} currentPage={1} />
            </div>
        </Modal>
    )
}

export default PopupViewPayouts;