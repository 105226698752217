import { useEffect, useState } from "react";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Link, useNavigate } from "react-router-dom";
import { getBankAccountTypeString, getFormattedLocalDate, getGenderString, getMaritalStatusString, persistCoachDisplayData } from "../common/Utilities";

const ProfileView: React.FC = () => {
    const navigate = useNavigate();

    const [coachDetail, setCoachDetail] = useState<APIData.Coach>();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        addProcessingRequests();
        ServerAPI.getProfile().then(response => {
            if (response.coaches !== undefined) {
                const coachDetail: APIData.Coach = response.coaches[0];
                setCoachDetail(coachDetail);
                persistCoachDisplayData(coachDetail.coach_full_name, coachDetail.coach_profile_picture);
            } else {
                toast.error('Could not fetch your profile details');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        {coachDetail !== undefined && (
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title text-dark-gray">My Profile</h4>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="col-xl-3 col-lg-3 d-flex justify-content-between">
                                        <div className="d-flex justify-content-between">
                                            <img src={coachDetail.coach_profile_picture !== '' ? coachDetail.coach_profile_picture : window.location.origin + "/assets/images/default-avatar.png"} className="listing-profile-image" />
                                        </div>
                                        <div className="ml-2">
                                            <p className="big-bold-purple-text mb-0">{coachDetail.coach_full_name}</p>
                                            <p className="small-sub-custom-text mb-0 line-height-medium">{coachDetail.coach_email}</p>
                                            <p className="small-sub-custom-text mb-0 line-height-medium">{coachDetail.coach_mobile}</p>
                                        </div>
                                    </div>
                                    <Link to={PageLinks.MY_PROFILE_UPDATE} className="edit-btn-custom mr-3">
                                        <i className="fas fa-edit"></i>
                                        <p className="edit-text mb-0 ml-1">Edit</p>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="w-25">
                                            <div>
                                                <p className="medium-small-grey mb-0">Date of Birth</p>
                                                <p className="points-results">{getFormattedLocalDate(coachDetail.coach_dob)}</p>
                                            </div>
                                            <div>
                                                <p className="medium-small-grey mb-0">Marital Status</p>
                                                <p className="points-results">{getMaritalStatusString(coachDetail.coach_marital_status)}</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div>
                                                <p className="medium-small-grey mb-0">Gender</p>
                                                <p className="points-results">{getGenderString(coachDetail.coach_gender)}</p>
                                            </div>
                                            <div>
                                                <p className="medium-small-grey mb-0">Profession</p>
                                                <p className="points-results">{coachDetail.coach_profession}</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div>
                                                <p className="medium-small-grey mb-0">Profile Name</p>
                                                <p className="points-results">{coachDetail.coach_preferred_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {coachDetail.coach_languages !== undefined && coachDetail.coach_languages.length > 0 && (
                                        <>
                                            <div className="bb-line mt-0"></div>
                                            <div className="w-100">
                                                <div>
                                                    <p className="section-sub-heading-inter-medium mb-2">Languages Known</p>
                                                    <div className="d-flex align-items-center mb-4">
                                                        {coachDetail.coach_languages.map((language, index) => <div className="language_btn mr-4" key={index}>{language.language_name}</div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {coachDetail.coach_social_medias !== undefined && coachDetail.coach_social_medias.length > 0 && (
                                        <>
                                            <div className="bb-line"></div>
                                            <div className="w-100">
                                                <div>
                                                    <p className="section-sub-heading-inter-medium mb-2">Social Media Handles</p>
                                                    <div className="d-flex align-items-center m-4">
                                                        {coachDetail.coach_social_medias.map((socialMedia, index) => (
                                                            <div className="d-flex align-items-center w-25" key={index}>
                                                                <i className={socialMedia.social_media_type_icon}></i>
                                                                <p className="section-sub-heading-inter-small">{socialMedia.coach_social_media_url}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {coachDetail.coach_verticals !== undefined && coachDetail.coach_verticals.length > 0 && (
                                        <>
                                            <div className="bb-line"></div>
                                            <div className="w-100">
                                                <div>
                                                    <p className="section-sub-heading-inter-medium mb-2">Verticals Requested</p>
                                                    <div className="d-flex align-items-center my-4">
                                                        {coachDetail.coach_verticals.map((vertical, index) => <div className="language_btn mr-4" key={index}>{vertical.vertical_name}</div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="bb-line"></div>
                                    <div className="w-100 d-flex">
                                        <div className="w-50">
                                            <p className="section-sub-heading-inter-medium mb-2">Unique Specializations</p>
                                            <p className="medium-small-grey inter">{coachDetail.coach_specialization}</p>
                                        </div>
                                        <div className="w-50">
                                            <p className="section-sub-heading-inter-medium">Wellness domain</p>
                                            <p className="medium-small-grey inter">{coachDetail.coach_website}</p>
                                        </div>
                                    </div>
                                    <div className="bb-line"></div>
                                    <div className="w-100 d-flex">
                                        <div className="w-50">
                                            <p className="section-sub-heading-inter-medium">Qualifications</p>
                                            <p className="medium-small-grey inter">{(coachDetail.coach_qualifications !== undefined) ? coachDetail.coach_qualifications.map(qualification => qualification.coach_qualification_text).join(',') : ''}</p>
                                        </div>
                                    </div>
                                    <div className="bb-line"></div>
                                    <div className="w-100">
                                        <p className="section-sub-heading-inter-medium">About You</p>
                                        <p className="medium-small-grey inter">{coachDetail.coach_writeup}</p>
                                    </div>
                                    <div className="bb-line"></div>
                                    <div className="w-100 d-flex">
                                        <div className="w-50">
                                            <p className="section-sub-heading-inter-medium">NIRC</p>
                                            <p className="medium-small-grey inter">{coachDetail.coach_nirc_number}</p>
                                        </div>
                                        <div className="w-50">
                                            <p className="section-sub-heading-inter-medium">Youtube Video ID</p>
                                            <p className="section-sub-heading-inter-small m-0">{coachDetail.coach_about_video}</p>
                                        </div>
                                    </div>
                                    {coachDetail.coach_photos !== undefined && coachDetail.coach_photos.length > 0 && (
                                        <div className="w-100 d-flex">
                                            <div className="w-50">
                                                <p className="section-sub-heading-inter-medium">Images</p>
                                                <div>
                                                    {coachDetail.coach_photos.map((photo, photoIdx) => <img src={photo.coach_photo} key={photoIdx} className="participant-task-images" />)}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="bb-line"></div>
                                    <p className="task-headings mb-2">Permanent Address</p>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Address Line 1</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_line1}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Address Line 2</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_line2}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Land Mark</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_landmark}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Pincode</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_pincode}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Country</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_country_name}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">State</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_state_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">City</p>
                                                <p className="points-results">{coachDetail.coach_perm_address_city}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bb-line"></div>
                                    <p className="task-headings mb-2">Current Address</p>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Address Line 1</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_line1}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Address Line 2</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_line2}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Land Mark</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_landmark}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Pincode</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_pincode}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Country</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_country_name}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">State</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_state_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">City</p>
                                                <p className="points-results">{coachDetail.coach_curr_address_city}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bb-line"></div>
                                    <p className="task-headings mb-2">Bank details</p>
                                    <div className="d-flex">
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Bank Account Number</p>
                                                <p className="points-results">{coachDetail.coach_bank_acc_number}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Bank Name</p>
                                                <p className="points-results">{coachDetail.coach_bank_name}</p>
                                            </div>
                                        </div>
                                        <div className="w-30">
                                            <div>
                                                <p className="medium-small-grey mb-0">Account Type</p>
                                                <p className="points-results">{getBankAccountTypeString(coachDetail.coach_bank_acc_type)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProfileView;
