const DetailSectionTabs: React.FC<{ tabs: string[], activeTabIndex: number, setActiveTabIndex: any }> = ({ tabs, activeTabIndex, setActiveTabIndex }) => {

    return (
        <div className="section-tabs">
            {tabs.map((tab, index) => {
                return (
                    <div key={index}>
                        <button type="button" className="link-button" onClick={() => setActiveTabIndex(index)}>
                            <div className={index === activeTabIndex ? "section-tab-purple" : "section-tab"}>
                                <p className="section-tabs-heading">{tab}</p>
                            </div>
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default DetailSectionTabs;