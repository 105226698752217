import { DateFormats, TimeFormats } from "../../common/Constants"
import { APIData } from "../../common/DataTypes"
import { getFormattedLocalDateTime } from "../../common/Utilities"


const ByteSizeDetail: React.FC<{ listingDetail: APIData.GeneralListing }> = ({ listingDetail }) => {

    return (
        <>
            <div className="d-flex">
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Heading</p>
                        <p className="points-results">{listingDetail.listing_title}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Reward Points</p>
                        <p className="points-results">{listingDetail.listing_reward_points}</p>
                    </div>
                </div>
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Vertical</p>
                        <p className="points-results">{listingDetail.vertical_name} &gt; {listingDetail.sub_vertical_name}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Price in Ringgit</p>
                        <p className="points-results">{listingDetail.listing_price > 0 ? listingDetail.listing_price : 'Free'}</p>
                    </div>
                </div>
                <div className="w-25">
                    <div>
                        <p className="medium-small-grey mb-0">Category</p>
                        <p className="points-results">{listingDetail.category_name}</p>
                    </div>
                    <div>
                        <p className="medium-small-grey mb-0">Last updated Date & Time</p>
                        <p className="points-results">{getFormattedLocalDateTime(listingDetail.listing_start_datetime, DateFormats.DDMONYYYY, TimeFormats.H12MIN_AMPM)}</p>
                    </div>
                </div>
            </div>
            <div className="bb-line"></div>
            <div className=" w-100">
                <div>
                    <p className="medium-small-grey mb-0">Describe the Challenge</p>
                    <p className="points-results">{listingDetail.listing_description}</p>
                </div>
            </div>
            <div className=" w-100">
                <div>
                    <p className="medium-small-grey mb-0">Expected Outcome</p>
                    <p className="points-results">{listingDetail.listing_expected_outcome}</p>
                </div>
            </div>
            <div className="w-100">
                <div>
                    <p className="medium-small-grey mb-0">Scientific Explanation</p>
                    <p className="points-results">{listingDetail.listing_scientific_justification}</p>
                </div>
            </div>
        </>
    )
}

export default ByteSizeDetail;