import { ErrorMessage, Field, FormikProps } from 'formik';
import { UIDataTypes } from '../../common/DataTypes';
import { FormHelperText } from '@mui/material';
import TimeDurationInput from './TimeDuration';
import { convertImageToBase64, getDisplayImageByURL, getFileTypeIcon } from '../../common/Utilities';
import { useState } from 'react';

const FormField: React.FC<{ formik: FormikProps<any>, fieldProps: UIDataTypes.FormFieldProps }> = ({ formik, fieldProps }) => {

    const { fieldType, error, errorText, label, initialValue, readOnly, options, ...otherProps } = fieldProps;
    const [changeImage, setChangeImage] = useState(false);
    const [uploadedFileType, setUploadedFileType] = useState('');
    const [uploadedURL, setUploadedURL] = useState('');

    return (
        <div className="mb-3">
            <label htmlFor={otherProps.name} className="mb-1 popup-subheadings">{label} {otherProps.required && (<span className="text-danger me-4">*</span>)}</label><br />
            {fieldType === 'text' && (
                <Field
                    type="text"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'number' && (
                <Field
                    type="number"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'date' && (
                <Field
                    type="date"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'time' && (
                <Field
                    type="time"
                    step={3600}
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'datetime' && (
                <Field
                    type="datetime-local"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'duration' && (
                <Field
                    component={TimeDurationInput}
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />

            )}
            {fieldType === 'textarea' && (
                <Field
                    component="textarea"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                >
                    {initialValue}
                </Field>
            )}
            {fieldType === 'password' && (
                <Field
                    type="password"
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                />
            )}
            {fieldType === 'select' && (
                <Field
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => formik.setFieldValue(otherProps.name, parseInt(e.target.value))}
                    id={otherProps.name}
                    {...otherProps}
                    readOnly={readOnly ? true : false}
                >
                    {options !== undefined && !options.some(option => option.value === 0) && (
                        <option value={0}>Select</option>
                    )}
                    {options && options.map((option, index) => {
                        return (
                            <option key={otherProps.name + '-option-' + index} value={option.value}>{option.label}</option>
                        )
                    })}
                </Field>
            )}
            {fieldType === 'fileupload' && (
                <>
                    {uploadedURL !== '' && !changeImage && (
                        <>
                            <img src={uploadedFileType.startsWith('image/') ? uploadedURL : getFileTypeIcon(uploadedFileType)} alt='' style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
                            <button type='button' className='btn-primary-small' onClick={() => setChangeImage(true)}>Change</button>
                        </>
                    )}
                    {formik.getFieldProps(fieldProps.name).value !== undefined && formik.getFieldProps(fieldProps.name).value !== '' && uploadedURL === '' && !changeImage && (
                        <>
                            <img src={getDisplayImageByURL(formik.getFieldProps(fieldProps.name).value)} alt='' style={{ maxWidth: '150px', maxHeight: '150px', marginRight: '10px' }} />
                            <button type='button' className='btn-primary-small' onClick={() => setChangeImage(true)}>Change</button>
                        </>
                    )}
                    {((!formik.getFieldProps(fieldProps.name).value && !uploadedURL) || changeImage) &&
                        <div className="position-relative">
                            <i className="fas fa-upload"></i>
                            <input type="file" {...otherProps} readOnly={readOnly ? true : false} onChange={(event) => {
                                const target = event.target as HTMLInputElement;
                                const uploadedFile = (target.files as FileList)[0];
                                setUploadedFileType(uploadedFile.type);
                                setUploadedURL(URL.createObjectURL(uploadedFile));
                                formik.setFieldValue(otherProps.name + '_file', uploadedFile);
                                convertImageToBase64(uploadedFile, (base64String) => formik.setFieldValue(otherProps.name + '_base64', base64String))
                                setChangeImage(false)
                            }} />
                        </div>
                    }
                </>

            )}
            <ErrorMessage component={FormHelperText} name={otherProps.name} className="form-error-text" />
        </div>
    )
}

export default FormField;