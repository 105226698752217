import { useAppState, StateActionTypes } from "./AppState";

export const useAppStateAPI = () => {
    const { state, dispatch } = useAppState();

    return {
        collapseMenu: state.collapseMenu,
        setCollapseMenu: (collapseMenu) => dispatch({type:StateActionTypes.SET_MENU_COLLAPSE, collapseMenu:collapseMenu}),
        
        showMobileMenu: state.showMobileMenu,
        setShowMobileMenu: (showMobileMenu) => dispatch({type:StateActionTypes.SET_MOBILE_MENU_OPEN, showMobileMenu:showMobileMenu}),
        
        showAlert: state.showAlert,
        setShowAlert: (showAlert) => dispatch({type:StateActionTypes.SET_SHOW_ALERT, showAlert:showAlert}),
        
        alertData: state.alertData,
        setAlertData: (okHandler,header, message) => dispatch({type:StateActionTypes.SET_ALERT_DATA, alertData:{okHandler:okHandler,header:header, message:message}}),

        messageCount: state.messageCount,
        setMessageCount: (messageCount) => dispatch({type:StateActionTypes.SET_MESSAGE_COUNT, messageCount:messageCount}),

        processingRequests: state.processingRequests,
        addProcessingRequests: () => dispatch({type: StateActionTypes.ADD_PROCESSING_REQUESTS}),
        reduceProcessingRequests: () => dispatch({type: StateActionTypes.REDUCE_PROCESSING_REQUESTS})
    }
}

