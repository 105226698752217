import { APIData } from "./DataTypes";

export namespace InitialData {

    export const ListingByteTask: APIData.ListingByteTask = {
        listing_byte_task_id: 0,
        listing_byte_task_position: 0,
        listing_byte_task_schedule_day: 0,
        listing_byte_task_schedule_session: 0,
        listing_byte_task_schedule_custom_time: "",
        listing_byte_task_description: "",
    }

    export const ListingProgramSession: APIData.ListingProgramSession = {
        listing_program_session_id: 0,
        listing_program_session_datetime: "",
        listing_program_session_mode: 0,
        listing_program_session_duration_mins: 0,
        listing_program_session_description: "",
    }

    export const DraftListingEntry: APIData.DraftListingEntry = {
        draft_listing_id: 0,
        category_id: 0,
        sub_vertical_id: 0,
        listing_title: "",
        listing_banner_image: "",
        listing_about_video_url: "",
        listing_description: "",
        listing_scientific_justification: "",
        listing_expected_outcome: "",
        listing_reward_points: 0,
        listing_price: 0,
        listing_batch_size: 0,
        listing_start_datetime: "",
        listing_duration: 0,
        listing_mode: 0,
        listing_venue: "",
        listing_online_link: "",
        //listing_badge_template_image: "",
        listing_submit_for_review: 0,
        draft_listing_byte_tasks: [],
        draft_listing_program_sessions: []
    }

    export const ListingProgramMetric: APIData.ListingProgramMetric = {
        listing_program_metric_id: 0,
        listing_program_metric_name: "",
        listing_program_metric_desc: "",
        listing_program_metric_input_type: 0,
        listing_program_metric_unit: "",
        listing_id: 0
    }

    export const ListingProgramMetricRequest: APIData.ListingProgramMetricRequest = {
        listing_program_metric_request_id: 0,
        listing_program_metric_request_name: "",
        listing_program_metric_request_date: "",
        listing_program_metric_request_due_date: "",
        listing_id: 0,
    }

    export const CoachQualification: APIData.CoachQualification = {
        coach_qualification_id: 0,
        coach_qualification_text: "",
        coach_qualification_certificate: "",
    }

}