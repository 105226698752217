import { Link } from "react-router-dom";
import { UIDataTypes } from "../../common/DataTypes";
import { DateFormats, ProjectConfig, TimeFormats } from "../../common/Constants";
import React from "react";
import { convertTimestampToCustom, getFormattedLocalDate, getFormattedLocalDateTime, getFormattedLocalTime } from "../../common/Utilities";
import { TableMappings } from "../../common/TableMappings";

interface TableMainProps {
    tableDetail: UIDataTypes.TableDetail,
    tableRows: any[],
    totalPages: number,
    currentPage: number,
    setCurrentPage?: any,
    //sortField: string,
    //updateSortBy: any,
    //sortOrder: string,
    //pageMappingDetail: PageMappings.PageDetail,
    //setDataID: any,
    //setShowForm: any,
    hidePagination?: boolean,
    handlers?: any,
    //selectedRowIDs?: string[],
    //handleRowSelection?: any
}
const TableMain: React.FC<TableMainProps> = (props) => {

    const { tableDetail, tableRows, totalPages, currentPage, setCurrentPage, handlers } = props;

    return (
        <div className="table-responsive">
            <table className="table table-responsive-md my_listing">
                <thead>
                    <tr>
                        <th className="width80">Sr.No.</th>
                        {tableDetail.columns.map((column, index) => {
                            return (<th className={column.titleClass ? column.titleClass : ""} key={index}>{column.label}</th>)
                        })}
                        {tableDetail.actions !== undefined && (
                            <th>Action</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {tableRows.map((row, rowIdx) => {
                        return (
                            <tr key={'row-' + rowIdx}>
                                <td>{((currentPage - 1) * ProjectConfig.EntriesPerPage) + rowIdx + 1}</td>
                                {tableDetail.columns.map((column, colIdx) => {
                                    let cellData: any = '';
                                    if (column.type == 'custom') {
                                        cellData = React.createElement(column.component, { data: row });
                                    } else if (row[column.data_key] != null && row[column.data_key] != undefined) {
                                        cellData = row[column.data_key];
                                        if (column.mapped == true) {
                                            const mappedData = TableMappings.getMappedData(column.data_key, cellData, row);
                                            cellData = mappedData.mappingName;
                                        } else if (column.type == 'image') {
                                            cellData = <img className='table-image' src={row[column.data_key]}></img>
                                        } else if (column.type == 'date') {
                                            cellData = getFormattedLocalDate(cellData);
                                        } else if (column.type == 'datetime') {
                                            cellData = getFormattedLocalDateTime(cellData);
                                        } else if (column.type == 'time') {
                                            cellData = getFormattedLocalTime(cellData);
                                        } else if (column.type == 'datetimestamp') {
                                            cellData = convertTimestampToCustom(cellData, DateFormats.DDMONTHYYYY);
                                        } else if (column.type == 'timestamp') {
                                            cellData = convertTimestampToCustom(cellData, DateFormats.DDMONTHYYYY + ' ' + TimeFormats.H12MIN_AMPM);
                                        }
                                    }
                                    return (
                                        <td className={column.dataClass ? column.dataClass : ''} key={'col-' + rowIdx + '-' + colIdx}>{cellData}</td>
                                    )
                                })}
                                {tableDetail.actions !== undefined && (
                                    <th className="table-actions">
                                        {tableDetail.actions.map((action, index) => {
                                            if (action.type === "custom") {
                                                return (
                                                    React.createElement(
                                                        action.component, { data: row, key: index, handlers: handlers }
                                                    )
                                                )
                                            } else {
                                                let actionIcon = (action.type == "edit") ? "fas fa-pencil-alt" : "fa-solid fa-eye";
                                                if (action.icon !== undefined) {
                                                    actionIcon = action.icon;
                                                }
                                                let actionLink = '';
                                                if (action.link !== undefined) {
                                                    actionLink = action.link;
                                                }
                                                if (action.params !== undefined) {
                                                    const paramsStr = action.params.map(param => param.name + "=" + row[param.column]);
                                                    actionLink += '?' + paramsStr.join('&');
                                                }
                                                return (
                                                    <Link to={actionLink}><i className={actionIcon} key={'act-' + index}></i></Link>
                                                )
                                            }
                                        })}
                                    </th>
                                )}
                            </tr>
                        )
                    })}
                    {tableRows.length === 0 && (
                        <tr><td colSpan={tableDetail.columns.length + ((tableDetail.actions !== undefined) ? 2 : 1)}>No entries available!</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TableMain;