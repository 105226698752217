import { Link } from "react-router-dom";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { PageLinks } from "../../common/Constants";
import { useEffect, useState } from "react";
import { UIDataTypes } from "../../common/DataTypes";
import HeaderNotifications from "./HeaderNotifications";
import HeaderProfile from "./HeaderProfile";
import { getCoachProfileDetail } from "../../common/Utilities";

const Header: React.FC = () => {
    const { collapseMenu, setCollapseMenu } = useAppStateAPI();

    const [coachProfile, setCoachProfile] = useState<UIDataTypes.CoachProfile>();

    useEffect(() => {
        getCoachProfile();
        setTimeout(getCoachProfile, 1000);
    }, []);

    const getCoachProfile = () => {
        const coachProfileData = getCoachProfileDetail();
        setCoachProfile(coachProfileData);
    }

    return (
        <div>
            <div className="nav-header">
                <Link to={PageLinks.DASHBOARD} className="brand-logo">
                    <img className="logo-abbr" src={window.location.origin + "/assets/images/logo.png"} alt="" />
                    <h3 className="logo-compact">Sathwell</h3>
                    <h3 className="brand-title">Sathwell</h3>
                </Link>
                <div className="nav-control">
                    <div className={`hamburger ${collapseMenu ? "is-active" : ""}`} onClick={() => { document.getElementById("main-wrapper")?.classList.toggle("menu-toggle"); setCollapseMenu(!collapseMenu); }}>
                        <span className="line"></span><span className="line"></span><span className="line"></span>
                    </div>
                </div>
            </div>
            <div className="header">
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div></div>
                            <ul className="navbar-nav header-right">
                                {/* <HeaderNotifications /> */}
                                {coachProfile !== undefined && (
                                    <HeaderProfile coachProfile={coachProfile} />
                                )}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Header;
