import { Link } from "react-router-dom";
import { PageLinks } from "../../common/Constants";

const ActionEditLiveListing: React.FC<{ data: any, handlers: any }> = ({ data, handlers }) => {

    return (
        <Link to={PageLinks.LISTING_ENTRY + '?id=' + data['draft_listing_id']}><i className="fas fa-pencil-alt"></i></Link>
    )

}

export default ActionEditLiveListing;