import { createContext, useContext, useReducer } from 'react';

const initialState = {
    collapseMenu: false,
    showMobileMenu: false,
    showAlert: false,
    alertData: { okHandler: null, header: "Cancel", message: "" },
    messageCount: 0,
    processingRequests: 0
};

const AppStateContext = createContext(initialState);

export const StateActionTypes = {
    SET_MENU_COLLAPSE: "SET_MENU_COLLAPSE",
    SET_MOBILE_MENU_OPEN: "SET_MOBILE_MENU_OPEN",
    SET_SHOW_ALERT: "SET_SHOW_ALERT",
    SET_ALERT_DATA: "SET_ALERT_DATA",
    SET_MESSAGE_COUNT: "SET_MESSAGE_COUNT",
    ADD_PROCESSING_REQUESTS: "ADD_PROCESSING_REQUESTS",
    REDUCE_PROCESSING_REQUESTS: "REDUCE_PROCESSING_REQUESTS",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case StateActionTypes.SET_MENU_COLLAPSE:
            return {
                ...state, collapseMenu: action.collapseMenu
            }
        case StateActionTypes.SET_MOBILE_MENU_OPEN:
            return {
                ...state, showMobileMenu: action.showMobileMenu
            }
        case StateActionTypes.SET_SHOW_ALERT:
            return {
                ...state, showAlert: action.showAlert
            }
        case StateActionTypes.SET_ALERT_DATA:
            return {
                ...state, alertData: action.alertData
            }
        case StateActionTypes.SET_MESSAGE_COUNT:
            return {
                ...state, messageCount: action.messageCount
            }
        case StateActionTypes.ADD_PROCESSING_REQUESTS:
            return {
                ...state, processingRequests: state.processingRequests + 1
            }
        case StateActionTypes.REDUCE_PROCESSING_REQUESTS:
            return {
                ...state, processingRequests: state.processingRequests - 1
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const AppStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    )
}

export const useAppState = () => useContext(AppStateContext);