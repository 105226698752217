import { useLocation } from "react-router-dom";
import { CustomerByteChallengeStatus, PageLinks } from "../common/Constants";
import HeadSectionTabs from "../components/ui_components/HeadSectionTabs";
import { UIDataTypes } from "../common/DataTypes";
import TableMain from "../components/table/TableMain";
import { TableMappings } from "../common/TableMappings";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const ListingTabs: UIDataTypes.LinkTab[] = [
    { link: PageLinks.PENDING_COACH_FEEDBACK, title: "Pending" },
    { link: PageLinks.BYTE_SIZED_COACH_RESPONSES, title: "Submitted" },
]

const ByteSizeCoachResponses: React.FC = () => {

    const location = useLocation();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [tableRows, setTableRows] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const loadData = () => {
        const statuses: number[] = (location.pathname == PageLinks.PENDING_COACH_FEEDBACK) ? [CustomerByteChallengeStatus.Submitted] : [CustomerByteChallengeStatus.Completed];
        addProcessingRequests();
        ServerAPI.getCustomerByteChallenges(currentPage, statuses, searchText).then(response => {
            if (response.success) {
                if (response.customer_byte_challenges) {
                    setTableRows(response.customer_byte_challenges);
                    setTotalPages(response.total_pages);
                }
            } else {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        loadData();
    }, [location, searchText]);

    return (

        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title text-dark-gray">
                                    Byte Size Coach Responses
                                </h4>
                            </div>
                            <HeadSectionTabs tabs={ListingTabs} />
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-center px-4">
                                    <div><p className="small-text-heading mb-0 mr-3">Search:</p></div>
                                    <div className="main-search">
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-custom" placeholder="" onChange={e => setSearchText(e.target.value)} />
                                            <div className="input-group-append">
                                                <button className="btn btn-search-input" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="d-flex align-items-center px-4">
                                    <select name="option" id="option" className="filter-select mr-3">
                                        <option value="option">All</option>
                                        <option value="option">One</option>
                                        <option value="option">Two</option>
                                        <option value="option">Three</option>
                                    </select>
                                    <input placeholder="From Date" type="date" className="filter-select" />
                                    <p className="small-text-heading mb-0 ml-2 mr-2">TO</p>
                                    <input placeholder="To Date" type="date" className="filter-select" />
                                </div> */}
                            </div>

                            <div className="card-body">
                                <TableMain tableDetail={TableMappings.ByteSizeCoachResponses} tableRows={tableRows} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ByteSizeCoachResponses;
