import { useNavigate } from "react-router-dom";


const BackButton: React.FC = () => {
    const navigate = useNavigate();
    return (
        <button className="back-button-transparent" onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19.783" height="17.585" viewBox="0 0 19.783 17.585">
                <defs>
                    <clipPath id="clip-path">
                        <path
                            id="Arrow_Left"
                            data-name="Arrow Left"
                            d="M8.785,15.517a1.292,1.292,0,0,1,.007,1.713,1.029,1.029,0,0,1-1.559,0L.327,9.649a1.289,1.289,0,0,1,0-1.715L7.234.358a1.032,1.032,0,0,1,1.559,0,1.29,1.29,0,0,1-.007,1.713L3.753,7.586H18.681a1.154,1.154,0,0,1,1.1,1.206A1.16,1.16,0,0,1,18.681,10H3.753Z"
                            transform="translate(0 0)"
                            fill="#2e3e5c"
                        />
                    </clipPath>
                </defs>
                <g id="_Icons_Close_Copy_3" data-name="_Icons / Close Copy 3" transform="translate(0 0)">
                    <path
                        id="Arrow_Left-2"
                        data-name="Arrow Left"
                        d="M8.785,15.517a1.292,1.292,0,0,1,.007,1.713,1.029,1.029,0,0,1-1.559,0L.327,9.649a1.289,1.289,0,0,1,0-1.715L7.234.358a1.032,1.032,0,0,1,1.559,0,1.29,1.29,0,0,1-.007,1.713L3.753,7.586H18.681a1.154,1.154,0,0,1,1.1,1.206A1.16,1.16,0,0,1,18.681,10H3.753Z"
                        transform="translate(0 0)"
                        fill="#2e3e5c"
                    />
                </g>
            </svg>
            <p className="card-title text-dark-gray mb-0 ml-2">Back</p>
        </button>
    )
}

export default BackButton;