import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PageLinks, PageURLNames } from "../common/Constants";
import Sidebar from "../components/Sidebar";
import Header from "../components/template/Header";
import Dashboard from './Dashboard';
import ListingEntry from './ListingEntry';
import MyListings from './MyListings';
import ByteSizeCoachResponses from './ByteSizeCoachResponses';
import ParticipantDetails from "./ParticipantDetail";
import ListingDetail from './ListingDetail';
import ProfileView from './ProfileView';
import CoachProfileUpdate from './CoachProfileUpdate';
import Earnings from './Earnings';
import { useEffect, useState } from 'react';
import { isCustomerLoggedIn } from '../common/Utilities';


const PageTemplate: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [customerLogin, setCustomerLogin] = useState(false);

    useEffect(() => {
        if(isCustomerLoggedIn()) {
            setCustomerLogin(true);
        } else {
            navigate(PageLinks.LOGIN);
        }
    }, [location.pathname]);

    return (
        <div>
            {customerLogin && (
                <>
                    <Header />
                    <Sidebar activeLink={PageLinks.MY_LISTING} />
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <Routes>
                                        <Route path={PageURLNames.DASHBOARD} element={<Dashboard />} />
                                        <Route path={PageURLNames.LISTING_ENTRY} element={<ListingEntry />} />
                                        <Route path={PageURLNames.DRAFT_LISTINGS} element={<MyListings />} />
                                        <Route path={PageURLNames.SUBMITTED_LISTINGS} element={<MyListings />} />
                                        <Route path={PageURLNames.PUBLISHED_LISTINGS} element={<MyListings />} />
                                        <Route path={PageURLNames.DRAFT_LISTING_DETAIL} element={<ListingDetail />} />
                                        <Route path={PageURLNames.LISTING_DETAIL} element={<ListingDetail />} />
                                        <Route path={PageURLNames.BYTE_SIZED_COACH_RESPONSES} element={<ByteSizeCoachResponses />} />
                                        <Route path={PageURLNames.PENDING_COACH_FEEDBACK} element={<ByteSizeCoachResponses />} />
                                        <Route path={PageURLNames.MY_PROFILE_UPDATE} element={<CoachProfileUpdate />} />
                                        <Route path={PageURLNames.MY_PROFILE_VIEW} element={<ProfileView />} />
                                        <Route path={PageURLNames.PARTICIPANT_DETAILS} element={<ParticipantDetails />} />
                                        <Route path={PageURLNames.EARNINGS} element={<Earnings />} />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default PageTemplate;