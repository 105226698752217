import React, { Fragment, useEffect, useState } from "react";
import BackButton from "../components/ui_components/BackButton";
import DetailSectionTabs from "../components/ui_components/DetailSectionTabs";
import { APIData } from "../common/DataTypes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { formatMinutesToDisplayFormat, getFormattedLocalDate, getFormattedLocalDateTime, getListingDaySessionText, getListingModeString, getMetricInputTypeString } from "../common/Utilities";
import { DateFormats, ListCategory, ListingDaySession, PageLinks, TimeFormats } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import ByteSizeDetail from "../components/listing_detail/ByteSizeDetail";
import ProgramDetail from "../components/listing_detail/ProgramDetail";
import SessionWorkshopDetail from "../components/listing_detail/SessionWorkshopDetail";
import ArticleDetail from "../components/listing_detail/ArticleDetail";
import YouTubeVideo from "../components/listing_detail/YouTubeVideo";
import TableMain from "../components/table/TableMain";
import { TableMappings } from "../common/TableMappings";
import PopupMetricEntry from "../components/popups/PopupMetricEntry";
import PopupMetricRequestEntry from "../components/popups/PopupMetricRequestEntry";

const participantsTabs = ["Listing Details", "Participants"];
const programTabs = ["Listing Details", "Participants", "Performance Metrics", "Performance Metric Requests"];
const newsArticleTabs = ["Listing Details"];

const ListingDetail: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [activeTab, setActiveTab] = useState(0);
    const [listingTabs, setListingTabs] = useState<string[]>([]);
    const [listingID, setListingID] = useState(0);
    const [listingDetail, setListingDetail] = useState<APIData.GeneralListing>();
    const [byteTasks, setByteTasks] = useState<APIData.ListingByteTask[]>([]);
    const [programSessions, setProgramSesions] = useState<APIData.ListingProgramSession[]>([]);
    const [listingPurchases, setListingPurchases] = useState<APIData.ListingPurchase[]>([]);
    const [programMetrics, setProgramMetrics] = useState<APIData.ListingProgramMetric[]>([]);
    const [programMetricRequests, setProgramMetricRequests] = useState<APIData.ListingProgramMetricRequest[]>([]);
    const [editMetricData, setEditMetricData] = useState<APIData.ListingProgramMetric>();
    const [showMetricEntry, setShowMetricEntry] = useState(false);
    const [editMetricRequestData, setEditMetricRequestData] = useState<APIData.ListingProgramMetricRequest>();
    const [showMetricRequestEntry, setShowMetricRequestEntry] = useState(false);
    const [showFeedbackViewMore, setShowFeedbackViewMore] = useState(false);
    const [fullFeedbackContent, setFullFeedbackContent] = useState('');


    const loadListing = () => {
        if (listingID) {
            if (location.pathname == PageLinks.LISTING_DETAIL) {
                addProcessingRequests();
                ServerAPI.getListing(listingID).then(response => {
                    if (response.listings !== undefined) {
                        const respListing = response.listings[0];
                        setListingDetail(respListing);
                        if (respListing.listing_byte_tasks !== undefined) {
                            setByteTasks(respListing.listing_byte_tasks);
                        }
                        if (respListing.listing_program_sessions !== undefined) {
                            setProgramSesions(respListing.listing_program_sessions);
                        }
                        if (respListing.listing_program_metrics !== undefined) {
                            setProgramMetrics(respListing.listing_program_metrics);
                        }
                        if (respListing.listing_program_metric_requests !== undefined) {
                            setProgramMetricRequests(respListing.listing_program_metric_requests);
                        }
                        if (respListing.category_id == ListCategory.Program) {
                            setListingTabs(programTabs);
                        } else if (respListing.category_id == ListCategory.NewsArticle) {
                            setListingTabs(newsArticleTabs);
                        } else {
                            setListingTabs(participantsTabs);
                        }
                    } else {
                        toast.error('Could not fetch Listing Detail');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            } else {
                addProcessingRequests();
                ServerAPI.getDraftListing(listingID).then(response => {
                    if (response.draft_listings !== undefined) {
                        setListingDetail(response.draft_listings[0]);
                        const respListing = response.draft_listings[0];
                        if (respListing.draft_listing_byte_tasks !== undefined) {
                            setByteTasks(respListing.draft_listing_byte_tasks);
                        }
                        if (respListing.draft_listing_program_sessions !== undefined) {
                            setProgramSesions(respListing.draft_listing_program_sessions);
                        }
                        setListingTabs(["Listing Details"]);
                    } else {
                        toast.error('Could not fetch Listing Detail');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    const loadParticipants = () => {
        if (listingID) {
            addProcessingRequests();
            ServerAPI.getListingPurchases(listingID).then(response => {
                if (response.listing_purchases !== undefined) {
                    setListingPurchases(response.listing_purchases);
                } else {
                    toast.error('Could not fetch Participants');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const loadData = () => {
        if (activeTab == 1) {
            loadParticipants();
        } else {
            loadListing();
        }
    }

    const openMetricEntry = (edit?: boolean, index?: number) => {
        if (edit && index !== undefined) {
            setEditMetricData(programMetrics[index]);
        } else {
            setEditMetricData(undefined);
        }
        setShowMetricEntry(true);
    }

    const openMetricRequestEntry = (edit?: boolean, index?: number) => {
        if (edit && index !== undefined) {
            setEditMetricRequestData(programMetricRequests[index]);
        } else {
            setEditMetricRequestData(undefined);
        }
        setShowMetricRequestEntry(true);
    }

    useEffect(() => {
        loadData();
    }, [location, listingID, activeTab]);

    useEffect(() => {
        const paramListingID = searchParams.get('id');
        if (paramListingID) {
            setListingID(parseInt(paramListingID));
        }
    }, [searchParams]);

    const closePopup = (reloadData: false) => {
        setShowMetricEntry(false);
        setShowMetricRequestEntry(false);
        if (reloadData) {
            loadData();
        }
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <BackButton />
                            </div>
                            <div className="d-flex justify-content-between">
                                <DetailSectionTabs tabs={listingTabs} activeTabIndex={activeTab} setActiveTabIndex={setActiveTab} />
                                {(listingDetail !== undefined && location.pathname == PageLinks.LISTING_DETAIL) && (
                                    <button className="edit-btn-custom mr-3" onClick={() => navigate(PageLinks.LISTING_ENTRY + '?id=' + listingDetail.draft_listing_id)}><i className="fas fa-edit"></i><p className="edit-text mb-0 ml-1">Edit</p></button>
                                )}
                            </div>
                            {activeTab == 0 && listingDetail !== undefined && (
                                <>
                                    <div className="detailed-banner">
                                        {listingDetail.listing_banner_image !== '' && (
                                            <div className="detailed-banner-image mr-4">
                                            <img src={listingDetail.listing_banner_image} className="detailed-banner-image" />
                                            </div>
                                        )}
                                        {listingDetail.listing_about_video_url !== '' && (
                                            <div className="detailed-banner-image">
                                                <YouTubeVideo videoID="b4OH3vBANa4" />
                                            </div>
                                        )}
                                        {/* <img src={window.location.origin + "/assets/images/card/detox_ingredients.png"} className="detailed-banner-image ml-4" /> */}
                                    </div>
                                    <div className="card-body">
                                        {(listingDetail.category_id === ListCategory.ByteSizeChallenge) && (
                                            <ByteSizeDetail listingDetail={listingDetail} />
                                        )}
                                        {(listingDetail.category_id === ListCategory.Program) && (
                                            <ProgramDetail listingDetail={listingDetail} />
                                        )}
                                        {(listingDetail.category_id === ListCategory.Workshop || listingDetail.category_id === ListCategory.LiveSession) && (
                                            <SessionWorkshopDetail listingDetail={listingDetail} />
                                        )}
                                        {(listingDetail.category_id === ListCategory.NewsArticle) && (
                                            <ArticleDetail listingDetail={listingDetail} />
                                        )}
                                        <div className="bb-line"></div>
                                        {byteTasks.length > 0 && (
                                            <>
                                                <p className="section-sub-heading">Task Details</p>
                                                {byteTasks.map((byteTask, taskIndex) => {
                                                    return (
                                                        <Fragment key={taskIndex}>
                                                            <p className="task-headings mb-2">Task {taskIndex + 1}</p>
                                                            <div className="d-flex">
                                                                <div className="w-25">
                                                                    <div>
                                                                        <p className="medium-small-grey mb-0">Day</p>
                                                                        <p className="points-results">Day {byteTask.listing_byte_task_schedule_day}</p>
                                                                    </div>
                                                                </div>
                                                                {byteTask.listing_byte_task_schedule_session !== ListingDaySession.Custom ?
                                                                    <div className="w-25">
                                                                        <div>
                                                                            <p className="medium-small-grey mb-0">Session</p>
                                                                            <p className="points-results">{getListingDaySessionText(byteTask.listing_byte_task_schedule_session)}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="w-25">
                                                                        <div>
                                                                            <p className="medium-small-grey mb-0">Time</p>
                                                                            <p className="points-results">{byteTask.listing_byte_task_schedule_custom_time}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="w-100">
                                                                <div>
                                                                    <p className="medium-small-grey mb-0">Task Description</p>
                                                                    <p className="points-results">{byteTask.listing_byte_task_description}</p>
                                                                </div>
                                                            </div>
                                                            <div className="bb-line"></div>
                                                        </Fragment>
                                                    )
                                                })}
                                            </>
                                        )}
                                        {programSessions.length > 0 && (
                                            <>
                                                <p className="section-sub-heading">Session Details</p>
                                                {programSessions.map((session, sessionIndex) => {
                                                    return (
                                                        <Fragment key={sessionIndex}>
                                                            <p className="task-headings mb-2">Session {sessionIndex + 1}</p>
                                                            <div className="d-flex">
                                                                <div className="w-25">
                                                                    <div>
                                                                        <p className="medium-small-grey mb-0">Date &amp; Time</p>
                                                                        <p className="points-results">{getFormattedLocalDateTime(session.listing_program_session_datetime, DateFormats.DDMONYYYY, TimeFormats.H12MIN_AMPM)}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div>
                                                                        <p className="medium-small-grey mb-0">Duration</p>
                                                                        <p className="points-results">{formatMinutesToDisplayFormat(session.listing_program_session_duration_mins)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="w-25">
                                                                    <div>
                                                                        <p className="medium-small-grey mb-0">Mode</p>
                                                                        <p className="points-results">{getListingModeString(session.listing_program_session_mode)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-100">
                                                                <div>
                                                                    <p className="medium-small-grey mb-0">Task Description</p>
                                                                    <p className="points-results">{session.listing_program_session_description}</p>
                                                                </div>
                                                            </div>
                                                            <div className="bb-line"></div>
                                                        </Fragment>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                            {activeTab == 1 && (
                                <>
                                    <div className="card-header participants_header">
                                        <h4 className="card-title text-dark-gray">Participants List</h4>
                                        <div className="d-flex align-items-center px-4">
                                            <div><p className="small-text-heading mb-0 mr-3">Search:</p></div>
                                            <div className="main-search">
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-custom" placeholder="Search this blog" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-search-input" type="button"><i className="fa fa-search"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <TableMain tableDetail={TableMappings.Participants} tableRows={listingPurchases} totalPages={1} currentPage={1} />
                                    </div>
                                </>
                            )}
                            {activeTab == 2 && (
                                <>
                                    <div className="card-header participants_header">
                                        <h4 className="card-title text-dark-gray">Performance Metrics</h4>
                                        <div className="d-flex col-xl-6 align-items-center justify-content-evenly participants_header_search_full">
                                            <div className="d-flex align-items-center px-4 participants_header_search">
                                                <button onClick={() => openMetricEntry()} type="submit" className="bg-pink primary_btn text-primary btn-add-new">Add New</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-responsive-md my_listing">
                                                <thead>
                                                    <tr>
                                                        <th className="width80">Sr.No.</th>
                                                        <th>Metric Name</th>
                                                        <th>Metric Accepted Values</th>
                                                        <th>Metric Unit</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {programMetrics.map((metric, index) => (
                                                        <tr key={index}>
                                                            <td className="text-dark-gray">{index + 1}</td>
                                                            <td>{metric.listing_program_metric_name}</td>
                                                            <td>{getMetricInputTypeString(metric.listing_program_metric_input_type)}</td>
                                                            <td>{metric.listing_program_metric_unit}</td>
                                                            <td>{metric.listing_program_metric_desc}</td>
                                                            <td>
                                                                <div className="close_icon" onClick={() => openMetricEntry(true, index)}>
                                                                    <i className="fas fa-edit"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {programMetrics.length === 0 && (<tr><td colSpan={6}>No entries available!</td></tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab == 3 && (
                                <>
                                    <div className="card-header participants_header">
                                        <h4 className="card-title text-dark-gray">
                                            Performance Metrics
                                        </h4>
                                        <div className="d-flex col-xl-6 align-items-center justify-content-evenly participants_header_search_full">
                                            <div className="d-flex align-items-center px-4 participants_header_search">
                                                <button onClick={() => openMetricRequestEntry()} type="submit" className="bg-pink primary_btn text-primary btn-add-new">Add New</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-responsive-md my_listing">
                                                <thead>
                                                    <tr>
                                                        <th className="width80">Sr.No.</th>
                                                        <th>Request Name</th>
                                                        <th>Scheduled Date</th>
                                                        <th>Due Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {programMetricRequests.map((metricRequest, index) => (
                                                        <tr>
                                                            <td className="text-dark-gray">{index + 1}</td>
                                                            <td>{metricRequest.listing_program_metric_request_name}</td>
                                                            <td>{getFormattedLocalDate(metricRequest.listing_program_metric_request_date)}</td>
                                                            <td>{getFormattedLocalDate(metricRequest.listing_program_metric_request_due_date)}</td>
                                                            <td>
                                                                <div className="close_icon" onClick={() => openMetricRequestEntry(true, index)}>
                                                                    <i className="fas fa-edit"></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {programMetricRequests.length === 0 && (<tr><td colSpan={5}>No entries available!</td></tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {listingDetail !== undefined && (
                <>
                    <PopupMetricEntry openMetrics={showMetricEntry} closeDialog={closePopup} listingID={listingDetail.listing_id} metricData={editMetricData} />
                    <PopupMetricRequestEntry openMetricRequest={showMetricRequestEntry} closeDialog={closePopup} listingID={listingDetail.listing_id} metricRequestData={editMetricRequestData} />
                </>
            )}
        </div>
    )
}

export default ListingDetail;