import { Box } from '@mui/material';
import { useAppStateAPI } from '../../common/AppStateAPI';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';


const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
}));

const Preloader: React.FC = () => {
    const { processingRequests } = useAppStateAPI();

    return (
        <Box zIndex="tooltip">
            <StyledBackdrop open={processingRequests > 0}>
                <CircularProgress color="inherit" />
            </StyledBackdrop >
        </Box>
    );
}

export default Preloader;