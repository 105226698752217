import { ErrorMessage, FieldArray, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { BankAccountType, EndPoints, Gender, MaritalStatus, PageLinks, ProjectStrings } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Fragment, useEffect, useRef, useState } from "react";
import { APIData, UIDataTypes } from "../common/DataTypes";
import { toast } from "react-hot-toast";
import FormField from "../components/form/FormField";
import { getDataFromAPI } from "../common/Utilities";
import { InitialData } from "../common/InitialData";

const CoachProfileUpdate: React.FC = () => {

    const navigate = useNavigate();

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [languages, setLanguages] = useState<APIData.Language[]>([])
    const [socialMediaTypes, setSocialMediaTypes] = useState<APIData.SocialMediaType[]>([]);
    const [verticals, setVerticals] = useState<APIData.Vertical[]>([]);
    const [selectedPermanentCountryID, setSelectedPermanentCountryID] = useState(0);
    const [selectedCurrentCountryID, setSelectedCurrentCountryID] = useState(0);

    const [coachDetail, setCoachDetail] = useState<APIData.CoachEntry>();

    const [coachLanguages, setCoachLanguages] = useState<number[]>([]);
    const [coachVerticals, setCoachVerticals] = useState<number[]>([]);
    const [coachImages, setCoachImages] = useState<string[]>([]);

    const profilePicFileInputRef = useRef<HTMLInputElement>(null);
    const coachImgFileInputRef = useRef<HTMLInputElement>(null);
    const [coachProfilePictureBase64, setCoachProfilePictureBase64] = useState('');
    const paramQualification = 'coach_qualifications';

    const genderOptions: UIDataTypes.SelectOption[] = [
        { label: "Male", value: Gender.Male },
        { label: "Female", value: Gender.Female },
        { label: "Transgender", value: Gender.Transgender },
        { label: "NonBinary", value: Gender.NonBinary },
    ];
    const maritalStatusOptions: UIDataTypes.SelectOption[] = [
        { label: "Single", value: MaritalStatus.Single },
        { label: "Married", value: MaritalStatus.Married },
        { label: "Divorced", value: MaritalStatus.Divorced },
        { label: "Widowed", value: MaritalStatus.Widowed },
        { label: "Separated", value: MaritalStatus.Separated },
    ]
    const bankAccountTypeOptions: UIDataTypes.SelectOption[] = [
        { label: "Savings", value: BankAccountType.Savings },
        { label: "Current", value: BankAccountType.Current },
    ]

    const [countryOptions, setCountryOptions] = useState<UIDataTypes.SelectOption[]>([]);
    const [permanentStateOptions, setPermanentStateOptions] = useState<UIDataTypes.SelectOption[]>([]);
    const [currentStateOptions, setCurrentStateOptions] = useState<UIDataTypes.SelectOption[]>([]);
    const [availableSocialMedias, setAvailableSocialMedias] = useState<{ social_media_type_id: number, coach_social_media_url: string }[]>([]);

    const formikRef = useRef<FormikProps<APIData.CoachEntry>>(null);

    const getProfile = () => {
        addProcessingRequests();
        ServerAPI.getProfile().then(response => {
            if (response.coaches !== undefined) {
                const coachDetail: APIData.Coach = response.coaches[0];
                setCoachDetail(coachDetail);
            } else {
                toast.error('Could not fetch your profile details');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const getLanguages = () => {
        addProcessingRequests();
        ServerAPI.getLanguages().then(response => {
            if (response.languages !== undefined) {
                setLanguages(response.languages);
            } else {
                toast.error('Could not fetch Lanugages');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const getCountries = () => {
        getDataFromAPI(EndPoints.Countries, setCountryOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'country_id', 'country_name');
    }

    const getStates = (countryID: number, stateSetter: any) => {
        getDataFromAPI(EndPoints.States, stateSetter, addProcessingRequests, reduceProcessingRequests, { 'country_id': countryID }, true, 'state_id', 'state_name');
    }

    const getSocialMediaTypes = () => {
        addProcessingRequests();
        ServerAPI.getSocialMediaTypes().then(response => {
            if (response.social_media_types !== undefined) {
                setSocialMediaTypes(response.social_media_types);
            } else {
                toast.error('Could not fetch Social Media Types');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const getVerticals = () => {
        addProcessingRequests();
        ServerAPI.getVerticals().then(response => {
            if (response.verticals !== undefined) {
                setVerticals(response.verticals);
            } else {
                toast.error('Could not fetch Verticals');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const setInitialValues = () => {
        if (coachDetail !== undefined) {
            if (coachDetail.coach_verticals) {
                let selectedVerticals: number[] = []
                coachDetail.coach_verticals.map(vertical => selectedVerticals.push(vertical.vertical_id));
                setCoachVerticals(selectedVerticals);
            }

            if (coachDetail.coach_languages) {
                let selectedLanguages: number[] = []
                coachDetail.coach_languages.map(language => selectedLanguages.push(language.language_id));
                setCoachLanguages(selectedLanguages);
            }

            if (coachDetail.coach_social_medias !== undefined) {
                const intialSocialMedias = coachDetail.coach_social_medias.map(handle => ({ social_media_type_id: handle.social_media_type_id, coach_social_media_url: handle.coach_social_media_url }),);
                setAvailableSocialMedias(intialSocialMedias);
            }
        }
    }

    useEffect(() => {
        getProfile();
        getLanguages();
        getCountries();
        getSocialMediaTypes();
        getVerticals();
    }, []);

    useEffect(() => {
        setInitialValues();
    }, [coachDetail])

    useEffect(() => {
        if (selectedPermanentCountryID) {
            getStates(selectedPermanentCountryID, setPermanentStateOptions);
        }
    }, [selectedPermanentCountryID]);

    useEffect(() => {
        if (selectedCurrentCountryID) {
            getStates(selectedCurrentCountryID, setCurrentStateOptions);
        }
    }, [selectedCurrentCountryID]);

    const uploadProfileImage = () => {
        profilePicFileInputRef.current?.click();
    }

    const uploadCoachImage = () => {
        coachImgFileInputRef.current?.click();
    }

    const handleUploadProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        /*const base64Data = await convertFileToBase64(file) as string;
        console.log(base64Data);
        setCoachProfilePictureBase64(base64Data);*/
        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const base64String = reader.result as string;
                const img = new Image();
                img.src = base64String;

                img.onload = () => {
                    if (img.width === img.height && img.width >= 200) {
                        setCoachProfilePictureBase64(base64String);
                    } else {
                        toast.error('Upload Square images with same Width and Height. Minimum Width and Height is 200x200 pixels');
                    }
                };
                //setCoachProfilePictureBase64(base64String);
                // handle the Base64 string
            };

            reader.onerror = () => {
                console.error('Failed to read the file');
            };
        }
    }

    const handleUploadCoachImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result as string;
                console.log('Base 64 String', base64String);
                setCoachImages(imgs => [...imgs, base64String]);
                // handle the Base64 string
            };

            reader.onerror = () => {
                console.error('Failed to read the file');
            };
        }
    }

    const updateCoachLanguages = (languageID: number) => {
        if (!coachLanguages.includes(languageID)) {
            setCoachLanguages(coachLangs => [...coachLangs, languageID])
        } else if (coachLanguages.includes(languageID)) {
            setCoachLanguages(coachLanguages.filter(langID => langID !== languageID));
        }
    }

    const updateCoachVerticals = (verticalID: number) => {
        if (!coachVerticals.includes(verticalID)) {
            setCoachVerticals(coachVerts => [...coachVerts, verticalID])
        } else if (coachVerticals.includes(verticalID)) {
            setCoachVerticals(coachVerticals.filter(vertID => vertID !== verticalID));
        }
    }

    const updateAvailableSocialMedias = (socialMediaTypeID: number, socialMediaURL: string) => {
        if (socialMediaURL !== '') {
            const itemExists = availableSocialMedias.some((item) => item.social_media_type_id === socialMediaTypeID);
            if (!itemExists) {
                setAvailableSocialMedias([...availableSocialMedias, { social_media_type_id: socialMediaTypeID, coach_social_media_url: socialMediaURL }]);
            } else {
                const updatedItems = availableSocialMedias.map((item) => {
                    if (item.social_media_type_id === socialMediaTypeID) {
                        return { social_media_type_id: socialMediaTypeID, coach_social_media_url: socialMediaURL };
                    }
                    return item;
                });
                setAvailableSocialMedias(updatedItems);
            }
        } else {
            setAvailableSocialMedias(availableSocialMedias.filter(item => item.social_media_type_id !== socialMediaTypeID))
        }
    }

    const yupSchema = Yup.object().shape({
        coach_full_name: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_preferred_name: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_dob: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_mobile: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_gender: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_email: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_marital_status: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_about_video: Yup.string().required(ProjectStrings.ValidationRequired).matches(/^[a-zA-Z0-9_-]{11}$/, 'Enter valid Youtube Video ID'),
        coach_profession: Yup.string().required(ProjectStrings.ValidationRequired),
        //coach_qualification: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_writeup: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_specialization: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_perm_address_line1: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_perm_address_pincode: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_perm_address_country_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_perm_address_state_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_perm_address_city: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_curr_address_line1: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_curr_address_pincode: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_curr_address_country_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_curr_address_state_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_curr_address_city: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_bank_acc_number: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_bank_acc_name: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_bank_name: Yup.string().required(ProjectStrings.ValidationRequired),
        coach_bank_acc_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        coach_qualifications: Yup.array()
            .of(
                Yup.object().shape({
                    coach_qualification_text: Yup.string().required(ProjectStrings.ValidationRequired),
                })
            ),
        // coach_qualifications: Yup.array()
        //     .of(
        //         Yup.object().shape({
        //             coach_qualification_text: Yup.string().required(ProjectStrings.ValidationRequired),
        //             coach_qualification_certificate: Yup.string(),
        //             coach_qualification_certificate_base64: Yup.string(),
        //         }).test('CertificateEmpty', 'Qualification Upload Certificate is Required', function (item) {
        //             const { coach_qualification_certificate, coach_qualification_certificate_base64 } = item;
        //             return coach_qualification_certificate || coach_qualification_certificate_base64 ? true : false;
        //         })
        //     ),
    });

    const ValidationSchema = Yup.object().shape({
        field1: Yup.string(),
        field2: Yup.string(),
        field3: Yup.string(),
        mandatoryField: Yup.string().required('This field is required'), // the always required field
    }).test('AtLeastOneNotEmpty', 'At least one field should be filled', function (item) {
        const { field1, field2, field3 } = item;

        return field1 || field2 || field3 ? true : false;
    });

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        {coachDetail !== undefined && (
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title text-dark-gray">Add Personal Info</h4>
                                </div>
                                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={coachDetail} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                                    let validationFailed = false;
                                    let submitValues: any = { ...values };
                                    delete submitValues.coach_nirc_upload_file;
                                    if (coachProfilePictureBase64) {
                                        submitValues.coach_profile_picture_base64 = coachProfilePictureBase64;
                                    } else if (!coachProfilePictureBase64 && !coachDetail.coach_profile_picture) {
                                        toast.error('Please Add your Profile Image');
                                        validationFailed = true;
                                    }
                                    if (coachLanguages.length === 0) {
                                        toast.error('Please Choose atleast 1 Language');
                                        validationFailed = true;
                                    }
                                    if (coachVerticals.length === 0) {
                                        toast.error('Please Choose atleast 1 Vertical');
                                        validationFailed = true;
                                    }
                                    submitValues.coach_languages = coachLanguages.map(lang_id => {
                                        return { language_id: lang_id }
                                    });
                                    submitValues.coach_verticals = coachVerticals.map(vert_id => {
                                        return { vertical_id: vert_id }
                                    });
                                    submitValues.coach_photos = coachImages.map(image => {
                                        return { coach_photo_base64: image }
                                    })

                                    if (availableSocialMedias) {
                                        submitValues.coach_social_medias = availableSocialMedias;
                                    }

                                    console.log('Submit Data', submitValues);

                                    if (!validationFailed) {
                                        addProcessingRequests();
                                        ServerAPI.updateProfile(coachDetail.coach_id, submitValues).then(response => {
                                            if (response.success) {
                                                toast.success(response.message);
                                                if (coachDetail.coach_preferred_name) {
                                                    navigate(-1);
                                                } else {
                                                    navigate(PageLinks.MY_PROFILE_VIEW);
                                                }
                                            } else {
                                                toast.error(response.message);
                                            }
                                        }).finally(() => {
                                            setSubmitting(false);
                                            reduceProcessingRequests();
                                        });
                                    } else {
                                        console.log('Additional Validation Failed');
                                    }

                                }}>
                                    {(formikProps) => {
                                        if (formikProps.values.coach_perm_address_country_id !== undefined && formikProps.values.coach_perm_address_country_id !== selectedPermanentCountryID) {
                                            setSelectedPermanentCountryID(formikProps.values.coach_perm_address_country_id);
                                        }
                                        if (formikProps.values.coach_curr_address_country_id !== undefined && formikProps.values.coach_curr_address_country_id !== selectedCurrentCountryID) {
                                            setSelectedCurrentCountryID(formikProps.values.coach_curr_address_country_id);
                                        }
                                        return (
                                            <div className="card-body">
                                                <div className="name-section">
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                                            <div className="profilephoto" style={{}}>
                                                                {coachProfilePictureBase64 !== '' ?
                                                                    <img src={coachProfilePictureBase64} width="40" style={{ marginLeft: "auto", marginRight: "auto", display: "block", width: "100px", borderRadius: "50px" }} alt="" />
                                                                    :
                                                                    <img src={coachDetail.coach_profile_picture !== '' ? coachDetail.coach_profile_picture : window.location.origin + "/assets/images/default-avatar.png"} width="40" style={{ marginLeft: "auto", marginRight: "auto", display: "block", width: "100px", borderRadius: "50px" }} alt="" />
                                                                }
                                                                <div className="profilephoto_camera_icon" onClick={uploadProfileImage}><i className="fas fa-camera"></i></div>
                                                                <input type="file" accept="image/*" style={{ display: 'none' }} ref={profilePicFileInputRef} onChange={handleUploadProfileImage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_full_name", label: "Full Name", className: "popup-textbox", required: true, placeholder: "Enter Full Name" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "date", name: "coach_dob", label: "Date Of Birth", className: "popup-textbox", required: true, placeholder: "Enter DOB" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_preferred_name", label: "Profile Name", className: "popup-textbox", required: true, placeholder: "Enter Profile Name" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group mb-0">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_gender", label: "Gender", className: "popup-textbox", required: true, options: genderOptions }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_email", label: "Email ID", className: "popup-textbox", required: true, placeholder: "Enter Email ID" }} />
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_marital_status", label: "Marital Status", className: "popup-textbox", required: true, options: maritalStatusOptions }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_mobile", label: "Mobile Number", className: "popup-textbox", required: true, placeholder: "Enter Mobile Number" }} />
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                            <div className="form-group">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_profession", label: "Profession", className: "popup-textbox", required: true, placeholder: "Enter Profession" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="languages" style={{ marginBottom: "20px" }}>
                                                        <label className="mb-1 popup-subheadings">Languages Known</label>
                                                        {languages.length > 0 && languages.map((language, langIdx) => {
                                                            return (
                                                                <Fragment key={langIdx}>
                                                                    &nbsp;&nbsp;&nbsp; <button className={`language_btn ${coachLanguages.includes(language.language_id) ? 'selected' : ''}`} onClick={() => updateCoachLanguages(language.language_id)}>{language.language_name}</button>
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>

                                                    <div className="socialmedia">
                                                        <label className="mb-1 popup-subheadings">Social Media Handles</label>
                                                        <div className="row">
                                                            {socialMediaTypes.map((socialMediaType, smIndex) => (
                                                                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12" key={smIndex}>
                                                                    <div className="form-group">
                                                                        <label>{socialMediaType.social_media_type_name}</label>
                                                                        <input type="text" className="popup-textbox" placeholder="Type Here" value={
                                                                            (availableSocialMedias.filter(sm => sm.social_media_type_id === socialMediaType.social_media_type_id).length > 0)
                                                                                ? availableSocialMedias.filter(sm => sm.social_media_type_id === socialMediaType.social_media_type_id)[0].coach_social_media_url
                                                                                : ''} onChange={(e) => updateAvailableSocialMedias(socialMediaType.social_media_type_id, e.target.value)}></input>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className="verticles" style={{ marginBottom: "20px" }}>
                                                        <label className="mb-1 popup-subheadings">Verticles Requested</label>
                                                        {verticals.length > 0 && verticals.map((vertical, langIdx) => {
                                                            return (
                                                                <Fragment key={langIdx}>
                                                                    &nbsp;&nbsp;&nbsp; <button className={`language_btn ${coachVerticals.includes(vertical.vertical_id) ? 'selected' : ''}`} onClick={() => updateCoachVerticals(vertical.vertical_id)}>{vertical.vertical_name}</button>
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="unique">
                                                        <div className="row">
                                                            <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", name: "coach_specialization", label: "Unique Specializations (Enter multiple values as comma seperated)", className: "comment-area", rows: 4, cols: 100 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="qualifications">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "coach_cv", label: "Upload CV", className: "popup-textbox banner-image-upload-box pl-5", accept: "application/msword, image/*, application/pdf" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_website", label: "Website", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="qualifications">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_qualification", label: "Qualifications", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="unique">
                                                        <div className="row">
                                                            <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", name: "coach_writeup", label: "About You", className: "comment-area", required: true, rows: 4, cols: 100 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="imagevideo">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_nirc_number", label: "NIRC Number", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "coach_nirc_upload", label: "NIRC Document Upload", className: "popup-textbox banner-image-upload-box pl-5", accept: "image/*, application/pdf" }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="imagevideo">
                                                        <label className="mb-1 popup-subheadings">Images</label>
                                                        <div>
                                                            <img src={window.location.origin + '/assets/images/upload_img.jpg'} alt="" className="participant-task-images" onClick={uploadCoachImage} />
                                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={coachImgFileInputRef} onChange={handleUploadCoachImage} />
                                                            {coachImages.map((coachImage, imgIdx) => <img key={imgIdx} src={coachImage} alt="" className="participant-task-images" />)}
                                                        </div>
                                                    </div>

                                                    <div className="video" style={{ marginTop: "10px" }}>
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_about_video", label: "About You - Youtube Video ID", className: "popup-textbox", required: true, placeholder: "" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bb-line"></div>
                                                    <FieldArray name={paramQualification}>
                                                        {({ insert, remove, push }) => (
                                                            <>
                                                                <div className="d-flex align-items-center mb-4">
                                                                    <h4 className="card-title text-dark-gray mr-4 mb-0">Qualifications</h4>
                                                                    <button className="btn-primary-small" onClick={() => push(InitialData.CoachQualification)}>Add More</button>
                                                                </div>
                                                                {formikProps.values[paramQualification] != undefined && formikProps.values[paramQualification].length > 0 &&
                                                                    <>
                                                                        {formikProps.values[paramQualification].map((data, index) => {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <div className="row">
                                                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: paramQualification + "." + index + ".coach_qualification_text", label: "Qualification", className: "popup-textbox", required: true }} />
                                                                                        </div>
                                                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: paramQualification + "." + index + ".coach_qualification_certificate", label: "Upload Certificate", className: "popup-textbox banner-image-upload-box pl-5" }} />
                                                                                            {/* <ErrorMessage name="coach_qualifications" component="div" /> */}
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-2 col-md-2 col-4">
                                                                                            <button className="btn-close-red ml-2 mt-4" onClick={() => remove(index)}><p>X</p></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                    <div className="bb-line"></div>
                                                    <div className="card-header px-0">
                                                        <h6>Permanent Address</h6>
                                                    </div>
                                                    <div className="address">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_perm_address_line1", label: "Address Line 1", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_perm_address_line2", label: "Address Line 2", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_perm_address_landmark", label: "Land Mark", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_perm_address_pincode", label: "Pincode", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_perm_address_country_id", label: "Country", className: "popup-textbox", options: countryOptions }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_perm_address_state_id", label: "State", className: "popup-textbox", options: permanentStateOptions }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_perm_address_city", label: "City", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-header px-0">
                                                        <h6>Current Address</h6>
                                                    </div>
                                                    <div className="address">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_curr_address_line1", label: "Address Line 1", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_curr_address_line2", label: "Address Line 2", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_curr_address_landmark", label: "Land Mark", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_curr_address_pincode", label: "Pincode", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_curr_address_country_id", label: "Country", className: "popup-textbox", options: countryOptions }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_curr_address_state_id", label: "State", className: "popup-textbox", options: currentStateOptions }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_curr_address_city", label: "City", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="bankdetails">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "coach_bank_acc_number", label: "Bank Account Number", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_bank_acc_name", label: "Bank Account Name", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_bank_name", label: "Bank Name", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group mb-0">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "coach_bank_acc_type", label: "Account Type", required: true, className: "popup-textbox", options: bankAccountTypeOptions }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-header px-0">
                                                        <h6>Referred By</h6>
                                                    </div>
                                                    <div className="address">
                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_referred_by_name", label: "Person Name", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_referred_by_contact", label: "Contact Number", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                                                <div className="form-group">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "coach_referred_by_relationship", label: "Relationship", className: "popup-textbox", placeholder: "Type Here" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="buttonsave" style={{ marginTop: "50px", marginBottom: "25px", textAlign: "right" }}>
                                                        <div className="row">
                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 ">
                                                                <button type="button" className="cancel_btn" onClick={() => navigate(-1)}>Cancel</button>
                                                                <button type="button" className="save_btn" onClick={() => {
                                                                    formikProps.submitForm();
                                                                    if (!formikProps.isValid) {
                                                                        console.log(formikProps.errors);
                                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                                    }
                                                                }} >Save</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }}
                                </Formik>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoachProfileUpdate;