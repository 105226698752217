import ActionEditLiveListing from "../components/table_action/ActionEditLiveListing";
import ActionViewPayouts from "../components/table_action/ActionViewPayouts";
import TableCellListingStatus from "../components/table_cell/TableCellListingStatus";
import { CustomerByteChallengeStatus, EarningPayoutStatus, PageLinks } from "./Constants";
import { UIDataTypes } from "./DataTypes";

export namespace TableMappings {
    export const DraftListing: UIDataTypes.TableDetail = {
        columns: [
            { label: "List ID", data_key: "draft_listing_id", type: "text" },
            { label: "Heading", data_key: "listing_title", type: "text" },
            { label: "Vertical", data_key: "vertical_name", type: "text" },
            { label: "Category", data_key: "category_name", type: "text" },
            { label: "Date & Time", data_key: "listing_update_time", type: "timestamp" },
            { label: "Status", data_key: "listing_update_time", type: "custom", component: TableCellListingStatus },
        ],
        actions: [
            { type: "edit", link: PageLinks.LISTING_ENTRY, params: [{ name: 'id', column: 'draft_listing_id' }] },
            { type: "detail", link: PageLinks.DRAFT_LISTING_DETAIL, params: [{ name: 'id', column: 'draft_listing_id' }] },
        ],
        primary_column: "draft_listing_id"
    }

    export const SubmittedListing: UIDataTypes.TableDetail = {
        columns: [
            { label: "List ID", data_key: "draft_listing_id", type: "text" },
            { label: "Heading", data_key: "listing_title", type: "text" },
            { label: "Vertical", data_key: "vertical_name", type: "text" },
            { label: "Category", data_key: "category_name", type: "text" },
            { label: "Date & Time", data_key: "listing_update_time", type: "timestamp" },
            { label: "Status", data_key: "listing_update_time", type: "custom", component: TableCellListingStatus },
        ],
        actions: [
            { type: "detail", link: PageLinks.DRAFT_LISTING_DETAIL, params: [{ name: 'id', column: 'draft_listing_id' }] },
        ],
        primary_column: "draft_listing_id"
    }

    export const Listing: UIDataTypes.TableDetail = {
        columns: [
            { label: "List ID", data_key: "listing_id", type: "text" },
            { label: "Heading", data_key: "listing_title", type: "text" },
            { label: "Vertical", data_key: "vertical_name", type: "text" },
            { label: "Category", data_key: "category_name", type: "text" },
            { label: "Date & Time", data_key: "listing_update_time", type: "timestamp" },
            { label: "Status", data_key: "listing_update_time", type: "custom", component: TableCellListingStatus },
        ],
        actions: [
            { type: "detail", link: PageLinks.LISTING_DETAIL, params: [{ name: 'id', column: 'listing_id' }] },
            { type: "custom", component: ActionEditLiveListing },
        ],
        primary_column: "listing_id"
    }

    export const Participants: UIDataTypes.TableDetail = {
        columns: [
            { label: "Photo", data_key: "customer_profile_image", type: "image" },
            { label: "Customer Name", data_key: "customer_name", type: "text" },
            { label: "Email ID", data_key: "customer_email", type: "text" },
            { label: "Mobile No", data_key: "customer_mobile", type: "text" },
            { label: "Rating", data_key: "listing_feedback_rating", type: "number" },
            { label: "Participants Feedback", data_key: "listing_feedback_text", type: "text" },
        ],
        actions: [
            { type: "detail", link: PageLinks.PARTICIPANT_DETAILS, params: [{ name: 'id', column: 'listing_purchase_id' }] },
        ],
        primary_column: "listing_purchase_id"
    }

    export const ByteSizeCoachResponses: UIDataTypes.TableDetail = {
        columns: [
            { label: "Customer Name", data_key: "customer_name", type: "text" },
            { label: "Category", data_key: "category_name", type: "text" },
            { label: "Heading", data_key: "listing_title", type: "text" },
            { label: "Vertical", data_key: "vertical_name", type: "text" },
            { label: "Date & Time", data_key: "customer_byte_challenge_status_time", type: "datetime" },
            { label: "Coach Response Status", data_key: "customer_byte_challenge_status", type: "number", mapped: true },
        ],
        actions: [
            { type: "detail", link: PageLinks.PARTICIPANT_DETAILS, params: [{ name: 'id', column: 'listing_purchase_id' }] },
        ],
        primary_column: "customer_byte_challenge_id"
    }

    export const Earnings: UIDataTypes.TableDetail = {
        columns: [
            { label: "Listing ID", data_key: "listing_id", type: "text" },
            { label: "Listing Title", data_key: "listing_title", type: "text" },
            { label: "Category", data_key: "category_name", type: "text" },
            { label: "Vertical", data_key: "vertical_name", type: "text" },
            { label: "Total Earnings", data_key: "vertical_name", type: "text" },
            { label: "Commission", data_key: "listing_purchase_commission", type: "text" },
            { label: "Other Deductions", data_key: "listing_purchase_other_deductions", type: "text" },
            { label: "Net Earnings", data_key: "listing_purchase_net_earnings", type: "text" },
            { label: "Amount Paid", data_key: "listing_purchase_paid_amount", type: "text" },
            { label: "Status", data_key: "listing_purchase_coach_payout_status", type: "text", mapped: true },
            { label: "Remarks", data_key: "listing_purchase_coach_payout_remarks", type: "text" },
        ],
        actions: [
            { type: "custom", component: ActionViewPayouts },
        ],
        primary_column: "listing_purchase_id"
    }

    export const Payouts: UIDataTypes.TableDetail = {
        columns: [
            { label: "Payout Date", data_key: "listing_purchase_coach_payout_date", type: "date" },
            { label: "Payout Amount", data_key: "listing_purchase_coach_payout_amt", type: "text" },
            { label: "Remarks", data_key: "listing_purchase_coach_payout_remarks", type: "text" },
        ],
        primary_column: "listing_purchase_coach_payout_id"
    }

    export interface MappingDetail {
        ID: number,
        mappingName: string,
    }

    export const getMappedData = (column_data_key: string, dataID: number, data?: any) => {
        let itemDetail: MappingDetail | undefined = undefined
        if (column_data_key == 'customer_byte_challenge_status') {
            if (dataID == CustomerByteChallengeStatus.Completed) {
                return { ID: 0, mappingName: 'Completed' }
            } else {
                return { ID: 0, mappingName: 'Pending' }
            }
        }
        if (column_data_key == 'listing_purchase_coach_payout_status') {
            if (dataID == EarningPayoutStatus.Completed) {
                return { ID: 0, mappingName: 'Completed' }
            } else {
                return { ID: 0, mappingName: 'Pending' }
            }
        }

        return itemDetail ? itemDetail : { ID: 0, mappingName: '' };
    }
}