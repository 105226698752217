import { useEffect, useState } from "react";
import { ListingStatus } from "../../common/Constants";
import { getListingStatusText } from "../../common/Utilities";


const TableCellListingStatus: React.FC<{ data: any }> = ({ data }) => {

    const [statusClass, setStatusClass] = useState('');
    const [statusString, setStatusString] = useState('');

    const getListingStatusDetails = () => {
        if (data.listing_status == ListingStatus.Draft) {
            setStatusClass('badge-draft');
        } else if (data.listing_status == ListingStatus.Submitted) {
            setStatusClass('badge-submitted');
        } else if (data.listing_status == ListingStatus.UnderReview) {
            setStatusClass('badge-under-review');
        } else if (data.listing_status == ListingStatus.Rejected) {
            setStatusClass('badge-rejected');
        } else if (data.listing_status == ListingStatus.Published) {
            setStatusClass('badge-published');
        } else if (data.listing_status == ListingStatus.Open) {
            setStatusClass('badge-open');
        } else if (data.listing_status == ListingStatus.Closed) {
            setStatusClass('badge-closed');
        } else if (data.listing_status == ListingStatus.Completed) {
            setStatusClass('badge-complete');
        } else if (data.listing_status == ListingStatus.OnHold) {
            setStatusClass('badge-on-hold');
        } else if (data.listing_status == ListingStatus.Terminated) {
            setStatusClass('badge-terminated');
        }
    }

    useEffect(() => {
        if (data !== undefined && data.listing_status !== undefined) {
            getListingStatusDetails();
            setStatusString(getListingStatusText(data.listing_status));
        }
    }, [data]);


    return (
        <span className={statusClass}>{statusString}</span>
    )
}

export default TableCellListingStatus;