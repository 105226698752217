import { useEffect, useState } from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { getDataFromAPI } from "../common/Utilities";
import { EndPoints } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import TableMain from "../components/table/TableMain";
import { TableMappings } from "../common/TableMappings";
import PopupViewPayouts from "../components/popups/PopupViewPayouts";


const Earnings: React.FC = () => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [verticals, setVerticals] = useState<APIData.Vertical[]>([]);
    const [categories, setCategories] = useState<APIData.Category[]>([]);

    const [tableRows, setTableRows] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [selectedCategoryID, setSelectedCategoryID] = useState(0);
    const [selectedVerticalID, setSelectedVerticalID] = useState(0);

    const [viewListingPurchaseID, setViewListingPurchaseID] = useState(0);
    const [showPayouts, setShowPayouts] = useState(false);

    useEffect(() => {
        console.log('Test');
        getVerticals();
        getCategories();
    }, [])

    useEffect(() => {
        loadData();
    }, [searchText, selectedCategoryID, selectedVerticalID])

    const getCategories = () => {
        getDataFromAPI(EndPoints.Categories, setCategories, addProcessingRequests, reduceProcessingRequests);
    }

    const getVerticals = () => {
        getDataFromAPI(EndPoints.Verticals, setVerticals, addProcessingRequests, reduceProcessingRequests);
    }

    const loadData = () => {
        addProcessingRequests();
        ServerAPI.getListingPurchaseEarnings(currentPage, searchText, selectedCategoryID, selectedVerticalID).then(response => {
            if (response.success) {
                if (response.listing_purchases) {
                    setTableRows(response.listing_purchases);
                    setTotalPages(response.total_pages);
                }
            } else {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const handleShowPayouts = (listingPurchaseID: number) => {
        setViewListingPurchaseID(listingPurchaseID);
        setShowPayouts(true);
    }

    return (

        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title text-dark-gray">My Listing</h4>
                            </div>
                            {/* <HeadSectionTabs tabs={ListingTabs} /> */}
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-center px-4">
                                    <div>
                                        <p className="small-text-heading mb-0 mr-3">Search:</p>
                                    </div>
                                    <div className="main-search">
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-custom" placeholder="" onChange={e => setSearchText(e.target.value)} />
                                            <div className="input-group-append">
                                                <button className="btn btn-search-input" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center px-4">
                                    <select name="option" id="option" className="filter-select mr-3" onChange={e => setSelectedCategoryID(parseInt(e.target.value))}>
                                        <option value={0}>All Categories</option>
                                        {categories.map((category, index) => <option value={category.category_id} key={index}>{category.category_name}</option>)}
                                    </select>
                                    <select name="option" id="option" className="filter-select mr-3" onChange={e => setSelectedVerticalID(parseInt(e.target.value))}>
                                        <option value={0}>All Verticals</option>
                                        {verticals.map((vertical, index) => <option value={vertical.vertical_id} key={index}>{vertical.vertical_name}</option>)}
                                    </select>
                                    {/* <input placeholder="From Date" type="date" className="filter-select" />
                                    <p className="small-text-heading mb-0 ml-2 mr-2">TO</p>
                                    <input placeholder="To Date" type="date" className="filter-select" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <TableMain tableDetail={TableMappings.Earnings} tableRows={tableRows} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} handlers={{ showPayouts: handleShowPayouts }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupViewPayouts showModal={showPayouts} closeModal={()=>setShowPayouts(false)} listingPurchaseID={viewListingPurchaseID} />
        </div>
    );
}

export default Earnings;