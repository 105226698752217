const TimeDurationInput: React.FC<{ field: any, form: any }> = ({ field, form, ...props }) => {
    const { name, value } = field;
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const handleHoursChange = (e: any) => {
        const newHours = parseInt(e.target.value);
        let newValue = newHours * 60;
        if(!isNaN(minutes)) {
            newValue += minutes;
        }
        form.setFieldValue(name, newValue);
    };

    const handleMinutesChange = (e: any) => {
        const newMinutes = parseInt(e.target.value);
        let newValue = newMinutes;
        if(!isNaN(hours)) {
            newValue += (hours * 60);
        }
        form.setFieldValue(name, newValue);
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <input
                        type="number"
                        name={`${name}-hours`}
                        min="0"
                        max="24"
                        value={hours}
                        onChange={handleHoursChange}
                        {...props}
                    />
                    <label htmlFor={`${name}-hours`}>Hours</label>
                </div>
                <div className="col-sm-6">
                    <input
                        type="number"
                        name={`${name}-minutes`}
                        min="0"
                        max="59"
                        value={minutes}
                        onChange={handleMinutesChange}
                        {...props}
                    />
                    <label htmlFor={`${name}-minutes`}>Minutes</label>
                </div>
            </div>
        </>
    );
};

export default TimeDurationInput;