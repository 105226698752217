import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { hideCollapseMenu } from "../common/Utilities";

import 'react-responsive-modal/styles.css';

const Sidebar: React.FC<{ activeLink: string }> = () => {
    const navigate = useNavigate();
    const { setCollapseMenu, collapseMenu, setMessageCount } = useAppStateAPI();
    const [msgCount, setMsgCount] = useState("");

    const location = useLocation();

    const [activeLink, setActiveLink] = useState('');

    useEffect(() => setActiveLink(location.pathname), [location]);


    return (
        <div className="deznav">
            <div className="deznav-scroll">
                <ul className="metismenu" id="menu">
                    <li onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)} >
                        <button onClick={() => navigate(PageLinks.LISTING_ENTRY)} type="submit" className="btn bg-pink primary_btn text-primary btn-block home_add_listing_btn">
                            <i className="fas fa-list"></i>
                            <span className="nav-text"> Add a Listing</span>
                        </button>
                    </li>
                    <li className={`${activeLink == PageLinks.DASHBOARD ? "mm-active active-no-child" : ""}`} onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)}>
                        <Link className={`${activeLink == PageLinks.DASHBOARD ? "ai-icon mm-active active-no-child" : ""}`} to={PageLinks.DASHBOARD}>
                            <img src={window.location.origin + '/assets/images/svg/icon-home.svg'} alt="" />
                            <span className="nav-text">Dashboard</span>
                        </Link>
                    </li>
                    <li className={`${activeLink == PageLinks.BYTE_SIZED_COACH_RESPONSES ? "mm-active active-no-child" : ""}`} onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)}>
                        <Link className={`${activeLink == PageLinks.BYTE_SIZED_COACH_RESPONSES ? "ai-icon mm-active active-no-child" : ""}`} to={PageLinks.PENDING_COACH_FEEDBACK}>
                            <img src={window.location.origin + '/assets/images/svg/icon-responses.svg'} alt="" />
                            <span className="nav-text">Byte Size Coach Responses</span>
                        </Link>
                        {/* <p className="message_menu_count_icon nav-count">{msgCount}</p> */}
                    </li>
                    <li className={`${activeLink == PageLinks.DRAFT_LISTINGS ? "mm-active active-no-child" : ""}`} onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)}>
                        <Link className={`${activeLink == PageLinks.DRAFT_LISTINGS ? "ai-icon mm-active active-no-child" : ""}`} to={PageLinks.DRAFT_LISTINGS}>
                            <img src={window.location.origin + '/assets/images/svg/icon-file-tick.svg'} alt="" />
                            <span className="nav-text">Listing</span>
                        </Link>
                    </li>
                    <li className={`${activeLink == PageLinks.EARNINGS ? "mm-active active-no-child" : ""}`} onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)}>
                        <Link className={`${activeLink == PageLinks.EARNINGS ? "ai-icon mm-active active-no-child" : ""}`} to={PageLinks.EARNINGS}>
                        <img src={window.location.origin + '/assets/images/svg/icon-file-tick.svg'} alt="" />
                            <span className="nav-text">Earnings</span>
                        </Link>
                    </li>
                    <li className={`${activeLink == PageLinks.MY_PROFILE_VIEW ? "mm-active active-no-child" : ""}`} onClick={() => hideCollapseMenu(collapseMenu, setCollapseMenu)}>
                        <Link className={`${activeLink == PageLinks.MY_PROFILE_VIEW ? "ai-icon mm-active active-no-child" : ""}`} to={PageLinks.MY_PROFILE_VIEW}>
                            <img src={window.location.origin + '/assets/images/svg/icon-user.svg'} alt="" />
                            <span className="nav-text">Profile</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
