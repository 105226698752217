
import axios from "axios";
import * as Utilities from "./Utilities"
import { API } from "./Constants";
import { toast } from "react-hot-toast";
import { APIData } from "./DataTypes";
export namespace ServerAPI {

    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    };

    export const resetPassword = async (resetKey: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["reset_key"] = resetKey;
        data["new_password"] = password;
        return executeAPI(API.EndPoint.CoachPasswordReset, APIMethod.POST, false, data);
    }

    export const coachLogin = async (mobile: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["mobile"] = mobile;
        data["password"] = password;
        return executeAPI(API.EndPoint.CoachLogin, APIMethod.POST, false, data);
    }

    export const getLanguages = async () => {
        return executeAPI(API.EndPoint.Languages, APIMethod.GET, true);
    }

    export const getCountries = async () => {
        return executeAPI(API.EndPoint.Countries, APIMethod.GET, true);
    }

    export const getStates = async (country_id: number) => {
        let params: { [k: string]: any } = {};
        params['country_id'] = country_id;
        return executeAPI(API.EndPoint.States, APIMethod.GET, true, null, params);
    }

    export const getSocialMediaTypes = async () => {
        return executeAPI(API.EndPoint.SocialMediaTypes, APIMethod.GET, true);
    }

    export const getByteSizeDays = async () => {
        return executeAPI(API.EndPoint.ByteSizeDays, APIMethod.GET, true);
    }

    export const getCategories = async () => {
        return executeAPI(API.EndPoint.Categories, APIMethod.GET, true);
    }

    export const getVerticals = async () => {
        return executeAPI(API.EndPoint.Verticals, APIMethod.GET, true);
    }

    export const getSubVerticals = async (vertical_id: number) => {
        let params: { [k: string]: any } = {};
        params['vertical_id'] = vertical_id;
        return executeAPI(API.EndPoint.SubVerticals, APIMethod.GET, true, null, params);
    }

    export const getProfile = async () => {
        let params: { [k: string]: any } = {};
        params['detailed'] = 1;
        return executeAPI(API.EndPoint.Coaches, APIMethod.GET, true, null, params);
    }

    export const updateProfile = async (coachID: number, profile: any) => {
        return executeAPI(API.EndPoint.Coaches + "/" + coachID, APIMethod.PUT, true, profile);
    }

    export const getCoachDashboard = async () => {
        return executeAPI(API.EndPoint.CoachDashboard, APIMethod.GET, true);
    }

    export const getDraftListings = async (pageNo: number, statuses?: number[], search?: string, categoryID?: number, verticalID?: number) => {
        let params: { [k: string]: any } = {};
        params['page_no'] = pageNo;
        if (statuses !== undefined) {
            params['statuses'] = statuses.join(',');
        }
        if (search) {
            params['search'] = search;
        }
        if (categoryID) {
            params['category_id'] = categoryID;
        }
        if (verticalID) {
            params['vertical_id'] = verticalID;
        }
        return executeAPI(API.EndPoint.DraftListings, APIMethod.GET, true, null, params);
    }

    export const getDraftListing = async (draftListingID: number) => {
        return executeAPI(API.EndPoint.DraftListings + "/" + draftListingID, APIMethod.GET, true);
    }

    export const addDraftListing = async (data: any) => {
        return executeAPI(API.EndPoint.DraftListings, APIMethod.POST, true, data);
    }

    export const updateDraftListing = async (draftListingId: number, data: any) => {
        return executeAPI(API.EndPoint.DraftListings + '/' + draftListingId, APIMethod.PUT, true, data);
    }

    export const getListings = async (pageNo: number, search?: string, categoryID?: number, verticalID?: number) => {
        let params: { [k: string]: any } = {};
        params['page_no'] = pageNo;
        if (search) {
            params['search'] = search;
        }
        if (categoryID) {
            params['category_id'] = categoryID;
        }
        if (verticalID) {
            params['vertical_id'] = verticalID;
        }
        return executeAPI(API.EndPoint.Listings, APIMethod.GET, true, null, params);
    }

    export const getListing = async (listingID: number) => {
        return executeAPI(API.EndPoint.Listings + "/" + listingID, APIMethod.GET, true);
    }

    export const getListingPurchases = async (lisingID?: number) => {
        let params: { [k: string]: any } = {};
        if (lisingID !== undefined && lisingID !== 0) {
            params['listing_id'] = lisingID;
        }
        return executeAPI(API.EndPoint.ListingPurchases, APIMethod.GET, true, null, params);
    }

    export const getListingPurchaseEarnings = async (pageNo: number, search?: string, categoryID?: number, verticalID?: number) => {
        let params: { [k: string]: any } = {};
        params['earnings'] = 1;
        params['page_no'] = pageNo;
        if (search) {
            params['search'] = search;
        }
        if (categoryID) {
            params['category_id'] = categoryID;
        }
        if (verticalID) {
            params['vertical_id'] = verticalID;
        }
        return executeAPI(API.EndPoint.ListingPurchases, APIMethod.GET, true, null, params);
    }

    export const getListingPurchase = async (listingPurchaseID: number) => {
        return executeAPI(API.EndPoint.ListingPurchases + '/' + listingPurchaseID, APIMethod.GET, true);
    }

    export const updateByteSizeCoachResponse = async (customerByteChallengeID: number, coachResponseText: string) => {
        let data: { [k: string]: any } = {};
        data["customer_byte_challenge_coach_feedback"] = coachResponseText;
        return executeAPI(API.EndPoint.CustomerByteChallenges + '/' + customerByteChallengeID, APIMethod.PUT, true, data);
    }

    export const getCustomerByteChallenges = (pageNo?: number, statuses?: number[], search?: string) => {
        let params: { [k: string]: any } = {};
        if (pageNo) {
            params['page_no'] = pageNo;
        }
        if (statuses !== undefined) {
            params['statuses'] = statuses.join(',');
        }
        if (search) {
            params['search'] = search;
        }
        return executeAPI(API.EndPoint.CustomerByteChallenges, APIMethod.GET, true, null, params);
    }

    export const addListingProgramMetric = (metricData: APIData.ListingProgramMetric) => {
        return executeAPI(API.EndPoint.ListingProgramMetrics, APIMethod.POST, true, metricData);
    }

    export const updateListingProgramMetric = (metricData: APIData.ListingProgramMetric) => {
        return executeAPI(API.EndPoint.ListingProgramMetrics + '/' + metricData.listing_program_metric_id, APIMethod.PUT, true, metricData);
    }

    export const addListingProgramMetricRequest = (metricData: APIData.ListingProgramMetricRequest) => {
        return executeAPI(API.EndPoint.ListingProgramMetricRequests, APIMethod.POST, true, metricData);
    }

    export const updateListingProgramMetricRequest = (metricData: APIData.ListingProgramMetricRequest) => {
        return executeAPI(API.EndPoint.ListingProgramMetricRequests + '/' + metricData.listing_program_metric_request_id, APIMethod.PUT, true, metricData);
    }

    export const getListingPurchaseCoachPayouts = async (listingPurchaseID: number) => {
        let params: { [k: string]: any } = {};
            params['listing_purchase_id'] = listingPurchaseID;
        return executeAPI(API.EndPoint.ListingPurchaseCoachPayouts, APIMethod.GET, true, null, params);
    }



    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any
    ) => {
        const persistAuthString = await Utilities.getAuthString();
        let headers: { [k: string]: any } = {};

        if (persistAuthString && authRequired) {
            headers["Authorization"] = persistAuthString;
        }
        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/x-www-form-urlencoded";
        }

        try {
            const response = await axios({
                method: method,
                url: API.BaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            if (response.status == 201 || response.status == 200) {
                return response.data;
            } else {
                if (response.data.message) {
                    toast.error(response.data.message);
                }
                console.log("Error", response);
            }
        } catch (error: any) {
            console.log("error", error);
            if (error.response.data) {
                toast.error(error.response.data);
            } else if (error.message) {
                toast.error(error.message);
            } else {
                toast.error("Error occurred while processing the request");
            }
        }
    }
}