
interface DashboardHighlightCardProps {
    iconImg: string,
    title: string,
    value: string | number
}

const DashboardHighlightCard: React.FC<DashboardHighlightCardProps> = ({ iconImg, title, value }) => {

    return (
        <div className="col-lg-3 col-sm-6">
            <div className="card">
                <div className="card-body">
                    <div className="media align-items-center">
                        <span className="mr-2 dash_icon_left">
                            <img src={iconImg} />
                        </span>
                        <div className="media-body ml-1">
                            <p className="mb-2">{title}</p>
                            <h3 className="mb-0 text-black font-w600">{value}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardHighlightCard;