import React from "react";
import YouTube, { YouTubeProps } from 'react-youtube';

interface YouTubePlayerProps {
    videoID: string;
    options?: YouTubeProps['opts']
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ videoID, options }) => {

    // const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    //     event.target.pauseVideo();
    // };

    // return <YouTube videoId={videoId} opts={options} onReady={onPlayerReady} style={{ width: "100%", height: "auto" }} />;

    return (
        <iframe
            className="home_video"
            width="100%" height="220"
            src={"https://www.youtube.com/embed/" + videoID + "?autoplay=1&modestbranding=1&controls=0&mute=1"}
            title=" "
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
    )
};

export default YouTubePlayer;