import { useEffect, useState } from "react";
import { EndPoints, ListingStatus, PageLinks } from "../common/Constants";
import { TableMappings } from "../common/TableMappings";
import TableMain from "../components/table/TableMain";
import { useLocation } from "react-router-dom";
import { APIData, UIDataTypes } from "../common/DataTypes";

import HeadSectionTabs from "../components/ui_components/HeadSectionTabs";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import { getDataFromAPI } from "../common/Utilities";

const ListingTabs: UIDataTypes.LinkTab[] = [
    { link: PageLinks.DRAFT_LISTINGS, title: "Draft" },
    { link: PageLinks.SUBMITTED_LISTINGS, title: "Submitted" },
    { link: PageLinks.PUBLISHED_LISTINGS, title: "Published" }
]

const MyListings: React.FC = () => {

    const location = useLocation();

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [verticals, setVerticals] = useState<APIData.Vertical[]>([]);
    const [categories, setCategories] = useState<APIData.Category[]>([]);

    const [tableDetail, setTableDetail] = useState<UIDataTypes.TableDetail>();
    const [tableRows, setTableRows] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [selectedCategoryID, setSelectedCategoryID] = useState(0);
    const [selectedVerticalID, setSelectedVerticalID] = useState(0);

    useEffect(() => {
        getVerticals();
        getCategories();
    }, [])

    useEffect(() => {
        if (location.pathname == PageLinks.DRAFT_LISTINGS) {
            setTableDetail(TableMappings.DraftListing);
        } else if (location.pathname == PageLinks.SUBMITTED_LISTINGS) {
            setTableDetail(TableMappings.SubmittedListing);
        } else if (location.pathname == PageLinks.PUBLISHED_LISTINGS) {
            setTableDetail(TableMappings.Listing);
        }
        loadData();
    }, [location]);

    useEffect(() => {
        loadData();
    }, [searchText, selectedCategoryID, selectedVerticalID])

    const getCategories = () => {
        getDataFromAPI(EndPoints.Categories, setCategories, addProcessingRequests, reduceProcessingRequests);
    }

    const getVerticals = () => {
        getDataFromAPI(EndPoints.Verticals, setVerticals, addProcessingRequests, reduceProcessingRequests);
    }

    const loadData = () => {
        if (location.pathname == PageLinks.DRAFT_LISTINGS || location.pathname == PageLinks.SUBMITTED_LISTINGS) {
            const statuses: number[] = (location.pathname == PageLinks.DRAFT_LISTINGS) ? [ListingStatus.Draft] : [ListingStatus.Submitted, ListingStatus.UnderReview];
            addProcessingRequests();
            ServerAPI.getDraftListings(currentPage, statuses, searchText, selectedCategoryID, selectedVerticalID).then(response => {
                if (response.success) {
                    if (response.draft_listings) {
                        setTableRows(response.draft_listings);
                        setTotalPages(response.total_pages);
                    }
                } else {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else if (location.pathname == PageLinks.PUBLISHED_LISTINGS) {
            addProcessingRequests();
            ServerAPI.getListings(currentPage, searchText, selectedCategoryID, selectedVerticalID).then(response => {
                if (response.success) {
                    if (response.listings) {
                        setTableRows(response.listings);
                        setTotalPages(response.total_pages);
                    }
                } else {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (

        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title text-dark-gray">My Listing</h4>
                            </div>
                            <HeadSectionTabs tabs={ListingTabs} />
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-center px-4">
                                    <div>
                                        <p className="small-text-heading mb-0 mr-3">Search:</p>
                                    </div>
                                    <div className="main-search">
                                        <div className="input-group">
                                            <input type="text" className="form-control form-control-custom" placeholder="" onChange={e => setSearchText(e.target.value)} />
                                            <div className="input-group-append">
                                                <button className="btn btn-search-input" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center px-4">
                                    <select name="option" id="option" className="filter-select mr-3" onChange={e => setSelectedCategoryID(parseInt(e.target.value))}>
                                        <option value={0}>All Categories</option>
                                        {categories.map((category, index) => <option value={category.category_id} key={index}>{category.category_name}</option>)}
                                    </select>
                                    <select name="option" id="option" className="filter-select mr-3" onChange={e => setSelectedVerticalID(parseInt(e.target.value))}>
                                        <option value={0}>All Verticals</option>
                                        {verticals.map((vertical, index) => <option value={vertical.vertical_id} key={index}>{vertical.vertical_name}</option>)}
                                    </select>
                                    {/* <input placeholder="From Date" type="date" className="filter-select" />
                                    <p className="small-text-heading mb-0 ml-2 mr-2">TO</p>
                                    <input placeholder="To Date" type="date" className="filter-select" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                {tableDetail !== undefined && (
                                    <TableMain tableDetail={tableDetail} tableRows={tableRows} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyListings;
