import { useState } from 'react';
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from '../common/Constants';


const ResetPassword: React.FC = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);

    const requestResetPassword = () => {
        if (searchParams.has('key')) {
            const resetKey = searchParams.get('key');
            if (!termsAgreed) {
                toast.error('Please agree to the Terms');
            } else if (password == '') {
                toast.error('Please enter your Password')
            } else if (retypePassword == '') {
                toast.error('Please enter your Retype Password')
            } else if (password != retypePassword) {
                toast.error('Your Password and Retype Password does not match')
            } else if (!resetKey) {
                toast.error('Invalid Key')
            } else {
                addProcessingRequests();
                ServerAPI.resetPassword(resetKey, password).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        navigate(PageLinks.LOGIN);
                    } else {
                        toast.error(response.message);
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        } else {
            toast.error('Key Missing!');
        }
    }

    return (
        <div className="authincation h-100vh login_bg">
            <div className="container h-100vh">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-12">
                        <div className="authincation-content white_bg">
                            <div className="row no-gutters">
                                <div className="col-xl-7">
                                    <div className="auth-form">
                                        <h4 className="text-left mb-4 text-white login_left_head">Welcome Back to Sathwell Coach Central</h4>
                                        <p className="text-light-blue">Congrats for joining Sathwell. Please set your password.</p>
                                        <div className="login_left_img">
                                            <img src={window.location.origin + "/assets/images/login_left_img.svg"} alt="Welcome Back to Sathwell Coach Central" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="auth-form login_right">
                                        <h3 className="text-left mb-4 text-dark-gray">Reset Password</h3>
                                        <form action="index.html" className="login_form">
                                            <div className="form-group">
                                                <label className="mb-1 text-dark-gray"><strong>New Password</strong></label>
                                                <input type="password" className="form-control" value={password} onChange={(evt) => setPassword(evt.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 text-dark-gray"><strong>Re-enter Your New Password</strong></label>
                                                <input type="password" className="form-control" value={retypePassword} onChange={(evt) => setRetypePassword(evt.target.value)} />
                                            </div>
                                            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox ml-1 text-white">
                                                        <input type="checkbox" className="custom-control-input text-dark-gray" checked={termsAgreed} onChange={(evt) => setTermsAgreed(evt.target.checked)} id="basic_checkbox_1" />
                                                        <label className="custom-control-label text-dark-gray" htmlFor="basic_checkbox_1">
                                                            I accept the <a href="#">Terms &amp; Conditions.</a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="button" className="btn bg-pink primary_btn text-primary btn-block" onClick={requestResetPassword}>Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
