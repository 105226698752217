import Modal from "react-responsive-modal"
import { APIData } from "../../common/DataTypes"
import { useAppStateAPI } from "../../common/AppStateAPI";
import { useEffect, useRef, useState } from "react";
import { InitialData } from "../../common/InitialData";
import { Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { ProjectStrings } from "../../common/Constants";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";
import FormField from "../form/FormField";


const PopupMetricRequestEntry: React.FC<{ openMetricRequest: boolean, closeDialog: any, listingID: number, metricRequestData?: APIData.ListingProgramMetricRequest }> = ({ openMetricRequest, closeDialog, listingID, metricRequestData }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [initialValue, setInitialValue] = useState<APIData.ListingProgramMetricRequest>(InitialData.ListingProgramMetricRequest);

    const formikRef = useRef<FormikProps<APIData.ListingProgramMetricRequest>>(null);

    const yupSchema = Yup.object().shape({
        listing_program_metric_request_name: Yup.string().required(ProjectStrings.ValidationRequired),
        listing_program_metric_request_date: Yup.date().required(ProjectStrings.ValidationRequired),
        listing_program_metric_request_due_date: Yup.date().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        if (metricRequestData !== undefined) {
            setInitialValue(metricRequestData);
        }
    }, [metricRequestData])

    return (
        <Modal open={openMetricRequest} onClose={() => closeDialog()} center>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title text-dark-gray">
                                Performance Metric Request Entry
                            </h4>
                        </div>
                        <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                            values.listing_id = listingID;
                            if (metricRequestData !== undefined) {
                                addProcessingRequests();
                                ServerAPI.updateListingProgramMetricRequest(values).then(response => {
                                    if (response && response.success) {
                                        toast.success(response['message']);
                                        closeDialog(true);
                                    } else {
                                        toast.error(response['message']);
                                    }
                                }).finally(() => {
                                    setSubmitting(false);
                                    reduceProcessingRequests();
                                });
                            } else {
                                addProcessingRequests();
                                ServerAPI.addListingProgramMetricRequest(values).then(response => {
                                    if (response && response.success) {
                                        toast.success(response['message']);
                                        closeDialog(true);
                                    } else {
                                        toast.error(response['message']);
                                    }
                                }).finally(() => {
                                    setSubmitting(false);
                                    reduceProcessingRequests();
                                });
                            }
                        }}>
                            {(formikProps) => {
                                return (
                                    <div className="card-body card-body-padding">
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_program_metric_request_name", label: "Request Name", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "date", name: "listing_program_metric_request_date", label: "Scheduled Date", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "date", name: "listing_program_metric_request_due_date", label: "Due Date", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="text-center">
                                            <button className="submit-btn" onClick={() => {
                                                formikProps.submitForm();
                                                if (!formikProps.isValid) {
                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                }
                                            }}>Update</button>
                                        </div>
                                    </div>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PopupMetricRequestEntry