import { Fragment, useEffect, useState } from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import DashboardHighlightCard from "../components/dashboard/DashboardHighlightCard";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { toast } from "react-hot-toast";
import { getCustomerProfileImage, getDurationSince, getFormattedLocalDate } from "../common/Utilities";
import { DateFormats, PageLinks } from "../common/Constants";
import DashboardPieChart from "../components/dashboard/DashboardPieChart";
import StarRating from "../components/ui_components/StarRating";
import { Link } from "react-router-dom";
import TableMain from "../components/table/TableMain";
import { TableMappings } from "../common/TableMappings";

const Dashboard: React.FC = () => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [coachDashboard, setCoachDashboard] = useState<APIData.CoachDashboard>();
    const [topListingCounts, setTopListingCounts] = useState<number[]>([]);
    const [topListingLabels, setTopListingLabels] = useState<string[]>([]);

    const loadDashboard = () => {
        addProcessingRequests();
        ServerAPI.getCoachDashboard().then(response => {
            if (response.coach_dashboard !== undefined) {
                const dashboardData: APIData.CoachDashboard = response.coach_dashboard;
                let listingCnts: number[] = [];
                let listingLbls: string[] = [];
                dashboardData.top_listings.map(listing => {
                    listingCnts.push(listing.total_purchased);
                    listingLbls.push(listing.listing_title);
                });
                setTopListingCounts(listingCnts);
                setTopListingLabels(listingLbls);
                setCoachDashboard(dashboardData);
            } else {
                toast.error('Could not fetch Dashboard Data');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        loadDashboard();
    }, [])

    return (
        <div className="row">
            {coachDashboard !== undefined && (
                <>
                    <DashboardHighlightCard iconImg={window.location.origin + "/assets/images/rating.png"} title="Our Average Rating" value={coachDashboard.average_rating} />
                    <DashboardHighlightCard iconImg={window.location.origin + "/assets/images/active_client.png"} title="Active Customers" value={coachDashboard.total_customers} />
                    <DashboardHighlightCard iconImg={window.location.origin + "/assets/images/listing.png"} title="Total Listing" value={coachDashboard.total_listing} />
                    <DashboardHighlightCard iconImg={window.location.origin + "/assets/images/feedback.png"} title="Total Feedbacks" value={coachDashboard.total_feedbacks} />
                    <div className="col-xl-8 col-xxl-12">
                        <div className="row">
                            {coachDashboard.upcoming_sessions.length > 0 && (
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header pb-0 border-0">
                                            <div className="mr-auto">
                                                <h4 className="fs-20 text-black mb-0">Upcoming Sessions</h4>
                                            </div>
                                        </div>
                                        <div className="card-body pt-2 pb-0">
                                            <div id="columnChart">
                                                {coachDashboard.upcoming_sessions.map((date, dtIndex) => (
                                                    <Fragment key={dtIndex}>
                                                        {date.schedule_tasks.map((session, sessIndex) => (
                                                            <div className="subcard1" style={{ marginBottom: "10px", marginTop: "10px", border: "2px solid #E9F1FB", borderRadius: '10px', padding: "20px 10px 20px 10px" }} key={sessIndex}>
                                                                <div className="media align-items-center">
                                                                    <span className="mr-2 upcoming_left"><img src={session.category_app_icon} /></span>
                                                                    <div className="media-body ml-1">
                                                                        <h4 className="mb-0 text-black font-w600">{session.listing_title}</h4>
                                                                        <p className="mb-2" style={{ color: "#93278F" }}>{session.vertical_name} | {session.category_name}</p>
                                                                        <h6>{getFormattedLocalDate(date.schedule_date, DateFormats.DDMONYYYY)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        )}
                                                    </Fragment>
                                                ))}
                                                {/* <div className="card-footer pb-0 border-0">
                                                    <div className="mr-auto">
                                                        <button type="button" className="btn btn-default" style={{ backgroundColor: "#E7F0FB", borderRadius: '10px', marginLeft: "30%", marginBottom: "30px", color: "#93278F" }} >View All <i className="fa fa-angle-right"></i></button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                {coachDashboard.top_listings.length > 0 && (
                                    <div className="rightcard1" style={{ backgroundColor: "white", borderRadius: '20px', padding: "20px 10px 20px 10px" }}>
                                        <h4>Top {coachDashboard.top_listings.length} Listings</h4>
                                        <div className="media align-items-center">
                                            {/* <img src={window.location.origin + "/assets/images/chart.jpg"} /> */}
                                            <DashboardPieChart data={topListingCounts} labels={topListingLabels} />
                                        </div>
                                    </div>
                                )}
                                {coachDashboard.listing_feedbacks.length > 0 && (
                                    <div className="rightcard2">
                                        <div className="headercard2">
                                            <h4>Recent Reviews</h4>
                                        </div>
                                        {coachDashboard.listing_feedbacks.map((feedback, index) => (
                                            <div className="commentbox1" key={index}>
                                                <div className="userimage">
                                                    <div className="image">
                                                        <img src={getCustomerProfileImage(feedback.customer_profile_image)} width="40" style={{ borderRadius: "20px" }} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h6>{feedback.customer_name} <div className="time_mins">{getDurationSince(feedback.listing_feedback_update_time)}</div></h6>
                                                        <div className="userstar">
                                                            {feedback.customer_points_earned > 0 && (
                                                                <><img src={window.location.origin + "/assets/images/points.png"} className="notify-points-img mr-7" />{feedback.customer_points_earned}</>
                                                            )}
                                                            {/* | Level:{feedback.customer_level}  */}
                                                        </div>
                                                        <div className="commentbox1_star">
                                                            <StarRating rating={feedback.listing_feedback_rating} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="feedback_listing_detail mt-5">{feedback.listing_title} - {feedback.category_name}</p>
                                                <div className="comments"><p>{feedback.listing_feedback_text}</p></div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {coachDashboard.recent_listings.length > 0 && (
                        <div className="col-xl-12 col-xxl-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title text-dark-gray">My Listing</h4>
                                            <Link to={PageLinks.PUBLISHED_LISTINGS} className="view_all">View All <i className="fa fa-angle-right"></i></Link>
                                        </div>
                                        <div className="card-body">
                                            <TableMain tableDetail={TableMappings.Listing} tableRows={coachDashboard.recent_listings} currentPage={1} totalPages={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {coachDashboard.pending_challenges.length > 0 && (
                        <div className="col-xl-12 col-xxl-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title text-dark-gray">Pending Coach Feedback</h4>
                                            <a href="ByteSizedCoachResponses" className="view_all">View All</a>
                                        </div>
                                        <div className="card-body">
                                            <TableMain tableDetail={TableMappings.ByteSizeCoachResponses} tableRows={coachDashboard.pending_challenges} currentPage={1} totalPages={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Dashboard;
