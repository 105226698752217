import { ChartData, ChartDataset, Chart, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { APIData, UIDataTypes } from '../../common/DataTypes';
import { getFormattedLocalDate } from '../../common/Utilities';
import { DateFormats } from '../../common/Constants';
Chart.register(...registerables);

interface Props {
    metric_updates: APIData.CustomerProgramMetricUpdate[];
}

const colors = [
    '#FF57BB', //Pink
    '#007FFF', //Blue
    '#7AC74F', //Medium Green
    '#7D83FF', //Purple
    '#F5B700', //Dark Yellow
    '#FF4B3E', //Orange Red
    '#7B287D', //Violet
    '#798086', //Grey
    '#262A10', //Dark Green
    '#D4AFCD', //Light Pink
];

type BarChartData = ChartData<'bar', number[], string>;

const MetricBarChart: React.FC<Props> = ({ metric_updates }) => {
    const [chartData, setChartData] = useState<BarChartData | null>(null);
    const [metricOptions, setMetricOptions] = useState<UIDataTypes.SelectOption[]>([]);
    const [filteredMetricID, setFilteredMetricID] = useState(0);

    useEffect(() => {
        if (metric_updates.length > 0 && metric_updates[0].customer_program_metric_values !== undefined) {
            const options: UIDataTypes.SelectOption[] = metric_updates[0].customer_program_metric_values.map((value) => ({ label: value.listing_program_metric_name, value: value.listing_program_metric_id }));
            setMetricOptions(options);
        }
    }, [metric_updates]);

    useEffect(() => {
        loadMetricData();
    }, [metric_updates, filteredMetricID]);

    const loadMetricData = () => {
        if (metric_updates.length > 0 && metric_updates[0].customer_program_metric_values !== undefined) {
            let filteredColor = colors[0];
            if (filteredMetricID !== 0) {
                metricOptions.forEach((option, index) => {
                    console.log('Check', option.value, index);
                    if (option.value === filteredMetricID) {
                        filteredColor = colors[index];
                    }
                })
            }

            const filteredUpdates = (filteredMetricID === 0) ? metric_updates : metric_updates.map((update) => ({ ...update, customer_program_metric_values: update.customer_program_metric_values?.filter(value => value.listing_program_metric_id === filteredMetricID) }));
            if (filteredUpdates !== undefined && filteredUpdates[0].customer_program_metric_values !== undefined) {
                const MetricValues = filteredUpdates[0].customer_program_metric_values;
                const labels = filteredUpdates.map((item) => getFormattedLocalDate(item.customer_program_metric_update_time, DateFormats.DDMONYYYY));
                const datasets: ChartDataset<'bar', number[]>[] = MetricValues.map((value, i) => {
                    return {
                        label: (MetricValues !== undefined) ? MetricValues[i].listing_program_metric_name : '',
                        data: filteredUpdates.map((item) => (item.customer_program_metric_values !== undefined) ? parseInt(item.customer_program_metric_values[i].customer_program_metric_value) : 0),
                        backgroundColor: filteredMetricID === 0 ? colors[i % colors.length] : filteredColor,
                    };
                });
                setChartData({ labels, datasets });
            }
        }
    }

    if (!chartData) {
        return null;  // Or some loading indicator
    }

    return (
        <div className="mt-3 bar_chart_full">
            <div className="bar_chart_head">Overall Progress</div>
            <div className="bar_chart_select">
                <select onChange={(e) => setFilteredMetricID(parseInt(e.target.value))}>
                    <option value={0}>All Metrics</option>
                    {metricOptions.map((option, index) => <option value={option.value} key={index}>{option.label}</option>)}
                </select>
            </div>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                }}
            />
        </div>
    );
};

export default MetricBarChart;