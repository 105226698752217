import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { APIData, UIDataTypes } from '../../common/DataTypes';
import * as Yup from 'yup';
import { MetricInputType, ProjectStrings } from '../../common/Constants';
import { InitialData } from '../../common/InitialData';
import FormField from '../form/FormField';
import { toast } from 'react-hot-toast';
import { ServerAPI } from '../../common/ServerAPI';

const PopupMetricEntry: React.FC<{ openMetrics: boolean, closeDialog: any, listingID: number, metricData?: APIData.ListingProgramMetric }> = ({ openMetrics, closeDialog, listingID, metricData }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [initialValue, setInitialValue] = useState<APIData.ListingProgramMetric>(InitialData.ListingProgramMetric);

    const formikRef = useRef<FormikProps<APIData.ListingProgramMetric>>(null);

    const inputTypeOptions: UIDataTypes.SelectOption[] = [{ label: "Text", value: MetricInputType.Text }, { label: "Number", value: MetricInputType.Number }];

    const yupSchema = Yup.object().shape({
        listing_program_metric_name: Yup.string().required(ProjectStrings.ValidationRequired),
        listing_program_metric_desc: Yup.string().required(ProjectStrings.ValidationRequired),
        listing_program_metric_input_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        listing_program_metric_unit: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        if (metricData !== undefined) {
            console.log('Edit Data', metricData);
            setInitialValue(metricData);
        }
    }, [metricData])

    return (
        <Modal open={openMetrics} onClose={() => closeDialog()} center>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title text-dark-gray">
                                Metrics Entry
                            </h4>
                        </div>
                        <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                            values.listing_id = listingID;
                            if (metricData !== undefined) {
                                addProcessingRequests();
                                ServerAPI.updateListingProgramMetric(values).then(response => {
                                    if (response && response.success) {
                                        toast.success(response['message']);
                                        closeDialog(true);
                                    } else {
                                        toast.error(response['message']);
                                    }
                                }).finally(() => {
                                    setSubmitting(false);
                                    reduceProcessingRequests();
                                });
                            } else {
                                addProcessingRequests();
                                ServerAPI.addListingProgramMetric(values).then(response => {
                                    if (response && response.success) {
                                        toast.success(response['message']);
                                        closeDialog(true);
                                    } else {
                                        toast.error(response['message']);
                                    }
                                }).finally(() => {
                                    setSubmitting(false);
                                    reduceProcessingRequests();
                                });
                            }
                        }}>
                            {(formikProps) => {
                                return (
                                    <div className="card-body card-body-padding">
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_program_metric_name", label: "Metric Name", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "listing_program_metric_input_type", label: "Metric Accepted Values", required: true, className: "popup-textbox", options: inputTypeOptions }} />
                                        </div>
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "text", name: "listing_program_metric_unit", label: "Metric Unit", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="mb-3">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", name: "listing_program_metric_desc", label: "Description", className: "popup-textbox", required: true, placeholder: "Type Here" }} />
                                        </div>
                                        <div className="text-center">
                                            <button type='button' className="submit-btn" onClick={() => {
                                                formikProps.submitForm();
                                                if (!formikProps.isValid) {
                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                }
                                            }}>Update</button>
                                        </div>
                                    </div>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>

        </Modal >
    );
};

export default PopupMetricEntry;
